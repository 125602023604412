import React, { useContext, useReducer } from 'react'
import { useLocalStorageState } from 'ahooks'

export const reducer = (state, action) => {
    switch (action?.type) {
        case 'updateUserInfo':
            return { ...action?.userInfo }
        default:
            throw new Error('Unexpected action')
    }
}

const UserContext = React.createContext()

export const useUserInfo = () => {
    const contextValue = useContext(UserContext)
    return contextValue
}

export const UserInfoProvider = ({ children }) => {
    //TODO需后端配合,才能实现登录状态持久化,暂时存储到storage里(不安全)
    const [userInfo] = useLocalStorageState('user')
    const contextValue = useReducer(reducer, userInfo || {})

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
