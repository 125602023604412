import {
    Calibrations,
    CreateCalibration,
    CalibrationDetail,
    CalibrationResult,
    FrequencyTest,
    FrequencyCreate,
    FrequencyCreateDetail,
    FrequencyResult,
    FunctionTest,
    FunctionCreate,
    FunctionTestDetail,
    TravelingTest,
    TravelingTestCreate,
    TravelingtestResult,
    TravelingtestDetail,
    ContinueTest,
    TestReport,
    TestReportDetail,
    BatchList,
    BatchInfo,
    BatchExecute,
    TestBatch,
    GPSTest,
    LowCurrentStart,
    TravelingElectricField,
    FrequencyElectricField,
    HighAndLowTemperature,
    Circumfluence,
    FrequencyAndWaveTest,
    SIMTest
} from './loader'

const testRouter = [
    {
        path: '/production-management/test/current-calibration-data',
        exact: true,
        component: Calibrations
    },
    {
        path: '/production-management/test/current-calibration-data/create',
        exact: true,
        component: CreateCalibration
    },
    {
        path: '/production-management/test/current-calibration-data/groupId/:id',
        exact: true,
        component: CalibrationDetail
    },
    {
        path: '/production-management/test/current-calibration-data/:id/result',
        exact: true,
        component: CalibrationResult
    },
    {
        // 工频测试
        path: '/production-management/test/current-frequency-data',
        exact: true,
        component: FrequencyTest
    },
    {
        path: '/production-management/test/current-frequency-data/create',
        exact: true,
        component: FrequencyCreate
    },
    {
        path: '/production-management/test/current-frequency-data/groupId/:id',
        exact: true,
        component: FrequencyCreateDetail
    },
    {
        path: '/production-management/test/current-frequency-data/:id/result',
        exact: true,
        component: FrequencyResult
    },
    {
        path: '/production-management/test/function-test',
        exact: true,
        component: FunctionTest
    },
    {
        path: '/production-management/test/function-test/create',
        exact: true,
        component: FunctionCreate
    },
    {
        path: '/production-management/test/function-test/:id/test-detail',
        exact: true,
        component: FunctionTestDetail
    },
    {
        path: '/production-management/test/traveling-test',
        exact: true,
        component: TravelingTest
    },
    {
        path: '/production-management/test/traveling-test/create',
        exact: true,
        component: TravelingTestCreate
    },
    {
        path: '/production-management/test/traveling-test/:id/:groupId/result',
        exact: true,
        component: TravelingtestResult
    },
    {
        path: '/production-management/test/traveling-test/:groupId/detail',
        exact: true,
        component: TravelingtestDetail
    },
    {
        path: '/production-management/test/continue-test',
        exact: false,
        component: ContinueTest
    },
    {
        path: '/production-management/test/test-report',
        exact: true,
        component: TestReport
    },
    {
        path: '/production-management/test/test-report/result/:reportId',
        exact: true,
        component: TestReportDetail
    },
    {
        path: '/production-management/test/test-report/sure/:reportId',
        exact: true,
        component: TestReportDetail
    },
    {
        path: '/production-management/test/batch/quality',
        exact: true,
        component: BatchList
    },
    {
        path: '/production-management/test/batch/quality/info/:batchId',
        exact: true,
        component: BatchInfo
    },
    {
        path: '/production-management/test/batch/quality/execute/:batchId',
        exact: true,
        component: BatchExecute
    },
    {
        path: '/production-management/test/batch/test',
        exact: true,
        component: TestBatch
    },
    {
        path: '/production-management/test/GPSTest',
        exact: true,
        component: GPSTest
    },
    {
        path: '/production-management/test/low-current-start',
        exact: false,
        component: LowCurrentStart
    },
    {
        path: '/production-management/test/traveling-electric-field',
        exact: false,
        component: TravelingElectricField
    },
    {
        path: '/production-management/test/frequency-electric-field',
        exact: false,
        component: FrequencyElectricField
    },
    {
        path: '/production-management/test/high-low-temperature',
        exact: false,
        component: HighAndLowTemperature
    },
    {
        //环流测试
        path: '/production-management/test/circumfluence-test',
        exact: false,
        component: Circumfluence
    },
    {
        path: '/production-management/test/frequency-and-wave',
        exact: false,
        component: FrequencyAndWaveTest
    },
    {
        path: '/production-management/test/sim-test',
        exact: false,
        component: SIMTest
    }
]

export default testRouter
