import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Pagination, Autoplay, EffectCoverflow, Mousewheel } from 'swiper/swiper.esm'
import { debounce } from 'lodash'
import Model from './model'
import Logout from './logout'
import { isMirror } from '../../../../utils/utils'

import { PhoneFilled } from '@ant-design/icons'

import 'swiper/swiper.scss'
import 'swiper/modules/pagination/pagination.scss'
import 'swiper/modules/effect-coverflow/effect-coverflow.scss'

import './index.scss'

// console.log(useHistory())

const MySwiper = ({ menu }) => {
    const history = useHistory()

    const [scale, setScale] = useState(1)
    const [slideItems, setSlideItems] = useState([])
    const renderList = useRef([])

    const swiperRef = useRef(null)

    // 初始菜单list配置
    let list = [
        // {
        //     id: 411,
        //     title: '绩效考核',
        //     titleEn: 'performance appraisal',
        //     path: ''
        // },
        {
            id: 2,
            title: '生产管理',
            titleEn: 'Production management',
            path: '',
            position: '3'
        },
        {
            id: 7,
            title: '角色权限',
            titleEn: 'Role management',
            path: '',
            position: '9'
        },
        {
            id: 5,
            title: '产品项目',
            titleEn: 'Product item',
            path: '',
            position: '6'
        },
        {
            id: 3,
            title: '仓储管理',
            titleEn: 'Warehouse management',
            path: '',
            position: '5'
        },
        {
            id: 6,
            title: '统计分析',
            titleEn: 'statistical analysis',
            path: '',
            position: '1'
        },
        {
            id: 1,
            title: '公共业务',
            titleEn: 'Public business',
            path: '',
            position: '4'
        },
        // {
        //     id: 428,
        //     title: '投诉建议',
        //     titleEn: 'Complaints Suggestions',
        //     path: ''
        // },
        {
            id: 4,
            title: '营销管理',
            titleEn: 'marketing management',
            path: '',
            position: '2'
        },
        // {
        //     id: 544,
        //     title: '供应商管理',
        //     titleEn: 'Supplier management',
        //     path: ''
        // },
        {
            id: 812,
            title: '工作流管理',
            titleEn: 'Workflow management',
            path: '',
            position: '8'
        }
    ]

    // 初始化swiper
    const swInit = (sw) => {
        swiperRef.current = sw
        // 初始位置
        let i = Math.floor((slideItems.length - 1) / 2)
        // console.log(i)
        sw.slideTo(i)
    }
    // 切换sw
    const swChange = (sw) => {
        // console.log("swChange",sw.realIndex)
    }
    // 点击swiper item
    const swClick = (i, list) => {
        let sw = swiperRef.current
        let realIndex = sw.realIndex
        if (realIndex != i) {
            sw.slideTo(i)
        } else {
            // 进入板块
            // console.log('进入板块:', renderList.current)
            let list = renderList.current
            history.push(list[i].path)
        }
    }
    // 设置slider项
    const handleSlideItems = () => {
        // console.log(menu)
        // [ {id: "1", code: "1", accessText: "公共业务", path: "/public-service/terminals"} ]
        let idArr_temp = menu.map((a) => {
            return Number(a.id)
        })
        let newList = list
            .filter((a) => {
                return idArr_temp.includes(a.id)
            })
            .sort((a, b) => {
                return a.position - b.position
            })
            .map((a) => {
                let i = idArr_temp.indexOf(Number(a.id))
                let a_menu = menu[i]
                return Object.assign(a, {
                    path: a_menu.path
                })
            })
        // console.log(newList)
        renderList.current = newList

        let slideItems = newList.map((a, i) => {
            let modelConf = {
                i: i,
                id: a.id
            }
            return (
                <SwiperSlide
                    key={a.id}
                    onClick={() => {
                        swClick(i)
                    }}
                >
                    <div className="cont">
                        <div className="title">
                            <p>{a.title}</p>
                            <p className="en">{a.titleEn.toLocaleUpperCase()}</p>
                        </div>
                        <Model config={modelConf} />
                    </div>
                </SwiperSlide>
            )
        })

        setSlideItems(slideItems)
    }

    // 适配swiper高度
    const matchSwiperHeight = () => {
        let winH = window.innerHeight
        if (winH < 1080) {
            let scale = winH / 1080
            setScale(scale)
        }
    }

    useEffect(() => {
        handleSlideItems()

        // 适配swiper高度
        matchSwiperHeight()
        window.onresize = debounce(() => {
            matchSwiperHeight()
        }, 20)
    }, [])

    return (
        <div
            className="swiper-box"
            style={{
                transform: `scale(${scale})`
            }}
        >
            {slideItems.length > 0 && (
                <Swiper
                    modules={[Pagination, Autoplay, EffectCoverflow, Mousewheel]}
                    effect={'coverflow'}
                    centeredSlides={true}
                    slidesPerView={5}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: '-20%',
                        depth: 350,
                        slideShadows: false
                    }}
                    speed={800}
                    loop={false}
                    autoplay={false}
                    mousewheel={true}
                    observeParents={true}
                    observer={true}
                    pagination={{
                        clickable: true
                    }}
                    onSlideChange={swChange}
                    onSwiper={swInit}
                    ref={swiperRef}
                >
                    {slideItems}

                    {!isMirror() && <div className="logo"></div>}

                    <div className="phone">
                        <PhoneFilled />
                        400-680-9916
                    </div>
                    {/* <div className='back' onClick={backToLogin}>
						返回
					</div> */}
                    <Logout />
                </Swiper>
            )}
        </div>
    )
}

export default MySwiper

MySwiper.propTypes = {
    menu: PropTypes.array
}
