import lodash from 'lodash'
import axios from 'axios'
import pathToRegexp from 'path-to-regexp'
import qs from 'qs'

import { message } from '../components/message'

const fetch = (options = {}) => {
    const { method = 'get', data } = options
    let { url } = options

    const cloneData = lodash.cloneDeep(data)

    try {
        let domin = url.match(/[a-zA-z]+:\/\/[^/]*/) || ''
        if (domin) {
            ;[domin] = domin
            url = url.slice(domin.length)
        }
        const match = pathToRegexp.parse(url)
        url = pathToRegexp.compile(url)(data)
        for (const item of match) {
            if (item instanceof Object && item.name in cloneData) {
                delete cloneData[item.name]
            }
        }
        if (url.includes('/nodeApi') || url.includes('/kingdee')) {
            url = `${domin}${url}`
        } else {
            url = `${domin}/api${url}`
        }
    } catch (e) {
        message.error(e.message)
    }
    switch (method.toLowerCase()) {
        case 'get':
            return axios.get(url, {
                ...options,
                params: cloneData,
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
            })
        case 'delete':
            return axios.delete(url, {
                ...options,
                data: cloneData
            })
        case 'post':
            // 处理上传文件类型
            if (data instanceof FormData) {
                return axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            }
            return axios.post(url, cloneData)
        case 'put':
            // 处理上传文件类型
            if (data instanceof FormData) {
                return axios.put(url, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            }
            return axios.put(url, cloneData)
        case 'patch':
            return axios.patch(url, cloneData)
        default:
            return axios(options)
    }
}

function request(options, isMsg = true) {
    return new Promise((resolve, reject) => {
        fetch(options)
            .then((response) => {
                const { statusText, status, data } = response
                if (!data.code && data.code !== 0) {
                    // 兼容配置中心老接口的方法
                    resolve({
                        data,
                        success: true,
                        code: 0,
                        message: data.message || data.msg || statusText
                    })
                } else if (data.code === 0 || data.code === 200) {
                    resolve({
                        ...data,
                        success: true,
                        code: data.code || status,
                        message: data.message || data.msg || statusText
                    })
                } else {
                    if (isMsg) {
                        if (data?.message?.indexOf('登陆') >= 0 || data?.msg?.indexOf('登陆') >= 0) {
                            window.historys.push('/login')
                            return
                        } else {
                            message.error(
                                data.message ||
                                    (data.msg?.includes('Timeout on blocking read') ? '设备未响应' : data?.msg) ||
                                    statusText
                            )
                        }
                    }

                    if (data.code === 401) {
                        window.historys.push('/login')
                    }
                    if (data.code === 403) {
                        window.historys.push('/no-auth')
                    }
                    reject(data)
                }
            })
            .catch((error) => {
                console.error('error', error)
                const { response } = error
                let responseMessage = '请求失败请检查网络后重试!'
                if (!response) {
                    responseMessage = '连接超时,请检查网络后重试'
                } else if (response.status < 400) {
                    resolve(response.data)
                    return
                } else if (response.status === 401) {
                    message.error('登录超时,请重新登录!')
                    window.localStorage.removeItem('user')
                    window.historys.push('/login')
                    reject()
                    return
                } else if (response.status === 403) {
                    responseMessage = '没有接口权限'
                } else {
                    try {
                        const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data
                        responseMessage = typeof data === 'string' ? data : data.message || data.msg
                    } catch (e) {
                        console.log(e)
                    }
                }
                message.error(responseMessage?.includes('Timeout on blocking read') ? '设备未响应' : responseMessage)
                reject(responseMessage)
            })
    })
}

const apiGet = (url, data, option, isMsg) =>
    request(
        {
            method: 'get',
            ...option,
            url,
            data
        },
        isMsg
    )
const apiPost = (url, data, option, isMsg) =>
    request(
        {
            method: 'post',
            url,
            option,
            data
        },
        isMsg
    )
const apiPut = (url, data, isMsg) =>
    request(
        {
            method: 'put',
            url,
            data
        },
        isMsg
    )
const apiDelete = (url, data, option, isMsg) =>
    request(
        {
            method: 'delete',
            ...option,
            url,
            data
        },
        isMsg
    )
const apiPatch = (url, data, isMsg) =>
    request(
        {
            method: 'patch',
            url,
            data
        },
        isMsg
    )

export { request as fetch, apiGet, apiPost, apiPut, apiDelete, apiPatch, axios }
