import React from 'react'
import { useHistory } from 'react-router-dom'

import { apiGet } from '../../../../utils/http'
import { clearLocalStorage } from '../../../../utils/utils'

const Logout = () => {
    const history = useHistory()

    // 退出登录
    const onSignOut = () => {
        apiGet('/logout/success').then(() => {
            clearLocalStorage()
            history.push('/login')
        })
    }

    return (
        <div className="back" onClick={onSignOut}>
            退出
        </div>
    )
}
export default Logout
