

import {pSMenu} from '../views/public-service/public-service-menu'
import {pMMenu}  from '../views/production-management/production-management-menu'
import {wMMenu} from '../views/warehouse-management/warehouse-management-menu'
import {mMMenu} from '../views/marketing-management/marketing-management-menu'
import {pIMenu} from '../views/product-item/product-item-menu'
import {sAMenu} from '../views/statistical-analysis/statistical-analysis-menu'
import {rPMenu} from '../views/role-permission/role-permissions-menu'
import {kpiMenu} from '../views/kpi-management/kpi-menu'
import {sMenu} from '../views/suggestions-management/s-menu'
import {supplierMenu} from '../views/supplier-management/supplier-menu'
import {workFlowMenu} from '../views/work-flow/work-flow-menu'


export const menuTwoConfig={
  '/public-service':{
    menuTwo:pSMenu
  },
  '/production-management':{
    menuTwo:pMMenu
  },
  '/warehouse-management':{
    menuTwo:wMMenu
  },
  '/marketing-management':{
    menuTwo:mMMenu
  },
  '/product-item':{
    menuTwo:pIMenu
  },
  '/statistical-analysis':{
    menuTwo:sAMenu
  },
  '/supplier-management':{
    menuTwo:supplierMenu
  },
  '/role-p':{
    menuTwo:rPMenu
  },
  '/kpi':{
    menuTwo:kpiMenu
  },
  "/suggestions":{
    menuTwo:sMenu
  },
  "/work-flow":{
    menuTwo:workFlowMenu
  }
}

