import React, { useState, useRef, forwardRef } from 'react'
import { Modal, Empty, Badge } from 'antd'
import { Link } from 'react-router-dom'
import { useBoolean } from 'ahooks'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SearchContentWrapper, RadioItem } from '../../../components/search-content'
import { useScrollToBottomHook, putReadAll } from './service'
import { SystemUpgrade, AuthorityChange } from './SysModal'
import { dealName, renderTime, LoadStatus, useMessageListHook, useOpenModalHook } from './tool'
import { useUserUnReadCount } from '../../../hooks/userHooks'
import './index.styl'

const MessageModal = () => {
    const [open, { setTrue, setFalse }] = useBoolean(false)
    const [messageType, setMessageType] = useState(2)
    const [page, setPage] = useState(1)

    const { data, loading, handleMessageList, reloadList, messageAllList, resetMessageList } = useMessageListHook({
        isRead: false
    })

    const totalPage = parseInt(data?.data?.totalPage) || 1

    const { loadUnReadCount, userInfo } = useUserUnReadCount()

    //滚动加载
    const ref = useRef(null)
    useScrollToBottomHook(ref, () => {
        if (page === totalPage) return
        reloadList(messageType, page + 1)
        setPage((page) => page + 1)
    })

    const handleOpen = () => {
        setTrue()
        reloadList(messageType)
    }

    const handleChangeMessage = (value) => {
        const type = parseInt(value?.target?.value)
        resetMessageList()
        setMessageType(type)
        setPage(1)
        reloadList(type)
    }
    const handleCloseModal = () => {
        setMessageType(2)
        setFalse()
        resetMessageList()
    }

    const isLoadAll = page === totalPage

    return (
        <>
            <div className="sys-message c-p" onClick={handleOpen}>
                <Badge count={userInfo?.unReadCount}>
                    <i className="iconfont icon-tongzhi"></i>
                </Badge>
            </div>
            <Modal
                width={400}
                trigger="click"
                open={open}
                footer={null}
                onCancel={handleCloseModal}
                closable={false}
                mask={false}
                className="sys-message-modal"
            >
                <MessageContent
                    messageType={messageType}
                    handleChangeMessage={handleChangeMessage}
                    messageList={messageAllList}
                    ref={ref}
                    isLoadAll={isLoadAll}
                    loading={loading}
                    userId={userInfo?.id}
                    reloadList={reloadList}
                    loadUnReadCount={loadUnReadCount}
                    handleMessageList={handleMessageList}
                />
            </Modal>
        </>
    )
}

export default MessageModal

const MessageContent = forwardRef(
    (
        {
            messageType,
            handleChangeMessage,
            messageList,
            isLoadAll,
            loading,
            userId,
            reloadList,
            loadUnReadCount,
            handleMessageList
        },
        ref
    ) => {
        const { open, setFalse, sysUpdateDetailId, handleOpen } = useOpenModalHook({
            messageList,
            handleMessageList,
            loadUnReadCount,
            reloadList,
            messageType
        })

        return (
            <div className="message-content">
                <SearchContentWrapper>
                    <RadioItem value={messageType} onChange={handleChangeMessage}>
                        <RadioItem.Button value={2}>工作业务相关</RadioItem.Button>
                        <RadioItem.Button value={1}>系统通知</RadioItem.Button>
                    </RadioItem>
                </SearchContentWrapper>
                <div className="list-content scrollBar" ref={ref}>
                    {messageList?.length > 0 && renderMessageByType(messageType, messageList, handleOpen)}
                    <LoadStatus
                        isLoadAll={isLoadAll}
                        loading={loading}
                        messageList={messageList}
                        empty={
                            <div className="empty-content">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        }
                    />
                </div>
                <Footer
                    messageList={messageList}
                    userId={userId}
                    loadUnReadCount={loadUnReadCount}
                    reloadList={reloadList}
                    handleMessageList={handleMessageList}
                    messageType={messageType}
                />
                {/* 系统维护弹窗 */}
                <SystemUpgrade open={!!sysUpdateDetailId && open} handleClose={setFalse} sysId={sysUpdateDetailId} />
                {/* 权限变更弹窗 */}
                <AuthorityChange open={sysUpdateDetailId === '' && open} handleClose={setFalse} />
            </div>
        )
    }
)

MessageContent.propTypes = {
    messageType: PropTypes.number,
    handleChangeMessage: PropTypes.func,
    messageList: PropTypes.array,
    isLoadAll: PropTypes.bool,
    loading: PropTypes.bool,
    userId: PropTypes.string,
    reloadList: PropTypes.func,
    loadUnReadCount: PropTypes.func,
    handleMessageList: PropTypes.func
}

const renderMessageByType = (type, messageList, handleOpen) => {
    if (type === 2) {
        return messageList?.map((message, index) => (
            <WorkMessageItem key={index} message={message} handleOpen={handleOpen} />
        ))
    } else {
        return messageList?.map((message, index) => (
            <SysMessageItem key={index} message={message} handleOpen={handleOpen} />
        ))
    }
}

const WorkMessageItem = ({ message, handleOpen }) => {
    return (
        <Link to={message?.url} target="_blank">
            <div
                className={classNames('message-item', { 'is-read-item': message?.isRead })}
                onClick={() => handleOpen('', message?.id, message?.isRead, true)}
            >
                <div className="work-item-content">
                    <div className="user-name">{dealName(message?.receiveUserName)}</div>
                    <div className="info-content">
                        <span className="full-name">{message?.receiveUserName}</span>
                        <span className="message">{message?.content}</span>
                        {renderTime(message?.createTime)}
                    </div>
                </div>
            </div>
        </Link>
    )
}
WorkMessageItem.propTypes = {
    message: PropTypes.object,
    handleOpen: PropTypes.func
}

const SysMessageItem = ({ message, handleOpen }) => {
    return (
        <div className={classNames('message-item', { 'is-read-item': message?.isRead })}>
            <div className="sys-item-content">
                <div className="title">{message?.title}</div>
                <div
                    className="content c-p"
                    onClick={() => handleOpen(message?.url, message?.id, message?.isRead, false)}
                >
                    {message?.content}
                </div>
                {renderTime(message?.createTime)}
            </div>
        </div>
    )
}
SysMessageItem.propTypes = {
    message: PropTypes.object,
    handleOpen: PropTypes.func
}

const Footer = ({ messageList, userId, loadUnReadCount, reloadList, handleMessageList, messageType }) => {
    const handleReadAll = () => {
        putReadAll(userId).then((res) => {
            //重新加载已读数量
            if (res?.success) {
                const newMessageList = messageList?.map((message) => ({ ...message, isRead: true }))
                handleMessageList(newMessageList)
                loadUnReadCount()
                reloadList()
            }
        })
    }
    return (
        <div className={classNames('footer', { 'b-t-1': messageList?.length < 1 })}>
            <span className="c-p text-blue" onClick={handleReadAll}>
                全部已读
            </span>
            <span className="line"></span>
            <Link
                to={`/public-service/sys-message?messageType=${messageType}`}
                target="_blank"
                className="c-p text-blue"
            >
                查看更多
            </Link>
        </div>
    )
}
Footer.propTypes = {
    messageList: PropTypes.array,
    userId: PropTypes.string,
    loadUnReadCount: PropTypes.func,
    reloadList: PropTypes.func,
    handleMessageList: PropTypes.func,
    messageType: PropTypes.number
}
