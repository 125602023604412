import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'


// 排程管理-加载页面
// 信息录入
const InformationEntryPage = loadable(() => import('./informationEntry'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 工序组
const OperationsGroupPage = loadable(() => import('./operationsGroup'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 生产报表
const ReportPage = loadable(() => import('./report'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})


// 质检管理排程-路由配置
const qualityManagementScheduleRoutes = [
    {
        // 信息录入
        path: '/production-management/quality-management/informationEntry',
        exact: true,
        component: InformationEntryPage
    },
    {
        // 工序组
        path: '/production-management/quality-management/operationsGroup',
        exact: true,
        component: OperationsGroupPage
    },
    {
        // 生产报表
        path: '/production-management/quality-management/report',
        exact: true,
        component: ReportPage
    },
]

export default qualityManagementScheduleRoutes