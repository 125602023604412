import React, { useEffect, useRef } from 'react'
import lottie from "lottie-web"

import '../index.styl'

const AnimateBox = () => {
    let aniDom = useRef(null)

    useEffect(() => {
        // 渲染lottie动画
        // 动画资源配置使用远程链接
        let aniJsonPath = 'https://ubcloud.oss-cn-beijing.aliyuncs.com/animate/login/config.json'

        let ani = lottie.loadAnimation({
            container: aniDom.current, 
            renderer: "svg",
            prerender: true,
            loop: true,
            autoplay: true,
            path: aniJsonPath
        });

    }, [])

    return (
        <div className="login-animate" ref={aniDom}></div>
    )
}

export default AnimateBox
