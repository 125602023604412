import { AboutMe, AllAppeal, MyAppeal } from './loader'

const sRouters = [
    {
        path: '/suggestions/appeal/all-appeal',
        exact: true,

        component: AllAppeal
    },
    {
        path: '/suggestions/appeal/about-me',
        exact: true,

        component: AboutMe
    },
    {
        path: '/suggestions/appeal/mine-appeal',
        exact: true,

        component: MyAppeal
    }
]
export default sRouters
