import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const PotentialSuppliersPage = loadable(() => import('./potentialSuppliers'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const QualifiedSuppliersPage = loadable(() => import('./qualifiedSuppliers'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const PotentialSuppliers = (props) => <PotentialSuppliersPage {...props} />
export const QualifiedSuppliers = (props) => <QualifiedSuppliersPage {...props} />
