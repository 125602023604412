/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const ZPPage = loadable(() => import('./zp'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TSPage = loadable(() => import('./ts'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MaterialsPage = loadable(() => import('./zj/MaterialsJX'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const ProductPage = loadable(() => import('./zj/ProductJX'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const ZP = (props) => <ZPPage {...props} />
export const TS = (props) => <TSPage {...props} />
export const Materials = (props) => <MaterialsPage {...props} />
export const Product = (props) => <ProductPage {...props} />
