/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { filter, isEmpty, map, find, sortBy } from 'lodash'
import { Spin } from 'antd'

import Layout from './layout'
import routers from './config/router'
import Login from './views/user/login'
import Home from './views/home/index'
import NoMatch from './views/user/noMatch'
import NoAuth from './views/user/noAuth'
import { getAuthorityByUserId } from './service/service'
import { setLocalStorage } from './utils/utils'
import { menuTwoConfig } from './config/level-two-menu'
import { useUserInfo } from './hooks/userInfo'

const RegisterHistory = () => {
    const history = useHistory()
    window.historys = history
    const [userFunctionList, setUserFunctionList] = useState([])
    const [loading, setLoading] = useState(true)
    const [userInfo] = useUserInfo()

    const [userLevelOne, setUserLevelOne] = useState([])
    const [menuOneConfig, setMenuOneConfig] = useState([])
    const [menuTwoConfig, setMenuTwoConfig] = useState([])
    useEffect(() => {
        if (!userInfo?.id) return
        getAuthorityByUserId(userInfo?.id)
            .then((res) => {
                //用户权限返回平级，需前端处理多级目录结构
                const functionList = res?.data?.functionList
                if (functionList) {
                    setUserFunctionList(functionList)
                    setLocalStorage('userFunctionList', functionList)

                    const userLevelOne = filter(
                        functionList,
                        (ulO) => ulO.parentId === '0' && ulO.accessType === 1 && ulO.uri !== '/disabled'
                    )

                    const menuTree = filter(
                        functionList,
                        (access) => access.parentId !== '0' && access.accessType === 1
                    )
                    const userLevelTwo = filter(menuTree, (uFl) => uFl.code?.split('-').length - 1 === 1)
                    const userLevelThree = sortBy(
                        filter(menuTree, (uFl) => uFl.code?.split('-').length - 1 === 2),
                        ['score']
                    )
                    const userLevelFour = sortBy(
                        filter(menuTree, (uFl) => uFl.code?.split('-').length - 1 === 3 && uFl.uri?.includes('/')),
                        ['score']
                    )

                    const menuTwo = dealMenuConfig(
                        userLevelOne,
                        sortBy(userLevelTwo, ['score'], ['asc']),
                        userLevelThree,
                        userLevelFour
                    )
                    const menuOne = dealMenuOne(userLevelOne, menuTwo)
                    setUserLevelOne(userLevelOne)
                    setMenuOneConfig(menuOne)
                    setMenuTwoConfig(menuTwo)
                } else {
                    history.push('/no-auth')
                    setUserFunctionList([])
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setUserFunctionList([])
                history.push('/login')
            })
    }, [userInfo, history])
    useEffect(() => {
        // 没有用户信息跳转到登录界面
        if (isEmpty(userInfo) && history.location.pathname !== '/login') {
            history.push('/login')
        } else if (userInfo && history.location.pathname === '/' && userFunctionList?.length) {
            handleUserUrl(userFunctionList, history)
        }
    }, [history, userFunctionList, userInfo])

    return (
        <Switch>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/home">
                <Home />
            </Route>
            <Layout
                userLevelOne={userLevelOne}
                userLevelOneConfig={menuOneConfig}
                userLevelTwoConfig={menuTwoConfig}
                history={history}
            >
                {loading ? (
                    <div className="global-empty-loading-page">
                        <Spin />
                    </div>
                ) : (
                    <Switch>
                        {routers.map((router) => {
                            return (
                                <Route
                                    key={router.path}
                                    exact={router.exact}
                                    path={router.path}
                                    render={(routerProps) => <router.component {...routerProps} userInfo={userInfo} />}
                                ></Route>
                            )
                        })}
                        <Route exact path="/no-auth">
                            <NoAuth />
                        </Route>
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                )}
            </Layout>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    )
}

export default RegisterHistory

//处理用户第一个可以跳转的路由
export const handleUserUrl = (userFunctionList, history) => {
    const userLevelOne = filter(userFunctionList, (ulO) => ulO.parentId === '0')
    const userLevelTwo = filter(userFunctionList, (uFl) => uFl.parentId !== '0' && uFl.accessType === 1)

    const userLevelTwoUrl = filter(userLevelTwo, (ulT) => ulT.parentId === userLevelOne[0]?.id)
    const userLevelThree = sortBy(
        userLevelTwoUrl?.length &&
            filter(userFunctionList, (ulT) => ulT.parentId === userLevelTwoUrl[0]?.id && ulT.accessType === 1),
        ['score']
    )
    const userFirstUrl = userLevelOne?.length
        ? `${userLevelOne[0]?.uri}${userLevelTwoUrl[0]?.uri}${userLevelThree[0]?.uri || ''}`
        : 'no-auth'
    history.push(userFirstUrl)
}

const filterMenus = (menus, parentId) => {
    return filter(menus, (menu) => menu.parentId === parentId)
}

const dealMenuConfig = (userLevelOne, userLevelTwo, userLevelThree, userLevelFour) => {
    return map(userLevelTwo, (ulT) => {
        const levelOnePath = find(userLevelOne, (ulO) => ulO.id === ulT.parentId)
        const levelTwoConfig = (menuTwoConfig[levelOnePath?.uri] || {})['menuTwo']
        const levelThreePath = find(userLevelThree, (ulTR) => ulTR.parentId === ulT.id)
        const userLevelThreeConfig = map(filterMenus(userLevelThree, ulT.id), (ltC) => {
            ltC.path = `${levelOnePath?.uri}${ulT?.uri}${ltC?.uri || ''}`
            ltC.name = ltC?.accessText
            ltC.disabled = ltC.uri === '/disabled'
            ltC.children = map(filterMenus(userLevelFour, ltC.id), (levelFour) => {
                levelFour.path = `${levelOnePath?.uri}${ulT?.uri}${ltC?.uri || ''}${levelFour?.uri || ''}`
                levelFour.name = levelFour?.accessText
                levelFour.disabled = levelFour.uri === '/disabled'
                return levelFour
            })
            return ltC
        })

        ulT.path = `${levelOnePath?.uri}${ulT?.uri}${levelThreePath?.uri || ''}`
        ulT.disabled = ulT.uri === '/disabled'
        ulT.name = ulT.accessText
        ulT.icon = find(levelTwoConfig, (ltC) => ltC.name === ulT.accessText)?.icon
        ulT.children = sortBy(userLevelThreeConfig, ['score'])
        return ulT
    })
}

const dealMenuOne = (userLevelOne, menuTwo) => {
    return map(userLevelOne, (ulO) => {
        const firstLevelTwo = find(menuTwo, (uT) => uT.parentId === ulO.id && !uT.disabled)
        //筛选出一级目录默认可显示的路由
        ulO.path = firstLevelTwo?.path
        ulO.name = ulO.accessText
        return ulO
    })
}
