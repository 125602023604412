//cSpell:word zhongduanguanli boxingguanli yuanchengshengji chushihuapeizhi zhongduanpeizhi huiguishebei appbanben gerenshezhi yingjiangaojing yunpingtaishebei
export const pSMenu = [
    {
        name: '终端管理',
        icon: 'icon-zhongduanguanli-01'
    },
    {
        name: '波形管理',
        icon: 'icon-boxingguanli-01'
    },
    {
        name: '远程升级',
        icon: 'icon-yuanchengshengji-01'
    },
    {
        name: '初始化配置',
        icon: 'icon-chushihuapeizhi-01'
    },
    {
        name: '终端配置',
        icon: 'icon-zhongduanpeizhi-01'
    },
    {
        name: '回归设备',
        icon: 'icon-huiguishebei-01'
    },
    {
        name: '告警汇总',
        icon: 'icon-yingjiangaojing-01'
    },
    {
        name: 'APP版本',
        icon: 'icon-appbanben-01'
    },
    {
        name: 'WEB版本',
        icon: 'icon-appbanben-01'
    },
    {
        name: '个人设置',
        icon: 'icon-gerenshezhi-01'
    },
    {
        name: '参数设置',
        icon: 'icon-a-wenzizuoduiqi_align-left-one1-01'
    },
    {
        name: '整机追溯',
        icon: 'icon-congdongzhuangzhi_hunting-gear-01'
    },
    {
        name: '消息提醒',
        icon: 'icon-xitongxiaoxi-01'
    },
    {
        name: '设备调试',
        icon: 'icon-chanpinguanli-011'
    },
    {
        name: '数字证书签发',
        icon: 'icon-a-zhengshu_certificate1-01'
    },
    {
        name: 'SIM卡批量查询',
        icon: 'icon-SIMkapiliangchaxun'
    }
]
