/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'
import './components/common.styl'

const CalibrationsPage = loadable(() => import('./calibration'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const CreateCalibrationPage = loadable(() => import('./calibration/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const CalibrationDetailPage = loadable(() => import('./calibration/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const CalibrationResultPage = loadable(() => import('./calibration/result'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FrequencyTestPage = loadable(() => import('./frequency'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FrequencyCreatePage = loadable(() => import('./frequency/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FrequencyCreateDetailPage = loadable(() => import('./frequency/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FrequencyResultPage = loadable(() => import('./frequency/result'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FunctionTestPage = loadable(() => import('./functionTest'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FunctionCreatePage = loadable(() => import('./functionTest/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const FunctionTestDetailPage = loadable(() => import('./functionTest/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TravelingTestPage = loadable(() => import('./travelingtest'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TravelingTestCreatePage = loadable(() => import('./travelingtest/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TravelingtestResultPage = loadable(() => import('./travelingtest/result'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TravelingtestDetailPage = loadable(() => import('./travelingtest/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const ContinueTestPage = loadable(() => import('./continueTest/router'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TestReportPage = loadable(() => import('./report'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TestReportDetailPage = loadable(() => import('./report/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const BatchListPage = loadable(() => import('./batch/list'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const BatchInfoPage = loadable(() => import('./batch/list/info'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const BatchExecutePage = loadable(() => import('./batch/list/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TestBatchPage = loadable(() => import('./batch/testBatch'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const GPSTestPage = loadable(() => import('./GPSTest'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const ProductionListPage = loadable(() => import('./production'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const SuppliesPage = loadable(() => import('./supplies'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const ParamsConfigPage = loadable(() => import('./paramsConfig'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const AbnormalDevicePage = loadable(() => import('./abnormalDevice'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const ProductionPlanPage = loadable(() => import('./productionPlan2'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const ProductionOrderPage = loadable(() => import('./productionOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const LowCurrentStartPage = loadable(() => import('./lowCurrentStart'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TravelingElectricFieldPage = loadable(() => import('./travelingElectricField'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const FrequencyElectricFieldPage = loadable(() => import('./frequencyElectricField'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const HighAndLowTemperaturePage = loadable(() => import('./highAndLowTemperature'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const HalfTestPage = loadable(() => import('./productCheck'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const MaterialsPage = loadable(() => import('./materialList'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MaterialsCheckPage = loadable(() => import('./productCheck'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const HalfCheckPage = loadable(() => import('./productCheck'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const CircumfluenceTestPage = loadable(() => import('./circumfluenceTest'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const TerminalFlowPage = loadable(() => import('./terminalFlow'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//报检单
const InspectionOrderPage = loadable(() => import('./inspectionOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//工频叠加行波测试
const FrequencyAndWavePage = loadable(() => import('./frequencyAndWave'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const TestSchedulePages = loadable(() => import('./testSchedule/router'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//SIM卡测试
const SIMTestPage = loadable(() => import('./SIMTest/route'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const Calibrations = (props) => <CalibrationsPage {...props} />
export const CreateCalibration = (props) => <CreateCalibrationPage {...props} />
export const CalibrationDetail = (props) => <CalibrationDetailPage {...props} />
export const CalibrationResult = (props) => <CalibrationResultPage {...props} />
export const FrequencyTest = (props) => <FrequencyTestPage {...props} />
export const FrequencyCreate = (props) => <FrequencyCreatePage {...props} />
export const FrequencyCreateDetail = (props) => <FrequencyCreateDetailPage {...props} />
export const FrequencyResult = (props) => <FrequencyResultPage {...props} />
export const FunctionTest = (props) => <FunctionTestPage {...props} />
export const FunctionCreate = (props) => <FunctionCreatePage {...props} />
export const FunctionTestDetail = (props) => <FunctionTestDetailPage {...props} />
export const TravelingTest = (props) => <TravelingTestPage {...props} />
export const TravelingTestCreate = (props) => <TravelingTestCreatePage {...props} />
export const TravelingtestResult = (props) => <TravelingtestResultPage {...props} />
export const TravelingtestDetail = (props) => <TravelingtestDetailPage {...props} />
export const FrequencyAndWaveTest = (props) => <FrequencyAndWavePage {...props} />

export const ContinueTest = (props) => <ContinueTestPage {...props} />
export const TestReport = (props) => <TestReportPage {...props} />
export const TestReportDetail = (props) => <TestReportDetailPage {...props} />
export const BatchList = (props) => <BatchListPage {...props} />
export const BatchInfo = (props) => <BatchInfoPage {...props} />
export const BatchExecute = (props) => <BatchExecutePage {...props} />
export const TestBatch = (props) => <TestBatchPage {...props} />
export const GPSTest = (props) => <GPSTestPage {...props} />
export const ProductionList = (props) => <ProductionListPage {...props} />
export const Supplies = (props) => <SuppliesPage {...props} />
export const ParamsConfig = (props) => <ParamsConfigPage {...props} />
export const AbnormalDevice = (props) => <AbnormalDevicePage {...props} />
export const HighAndLowTemperature = (props) => <HighAndLowTemperaturePage {...props} />

export const ProductionPlan = (props) => <ProductionPlanPage {...props} />
export const ProductionOrder = (props) => <ProductionOrderPage {...props} />
export const LowCurrentStart = (props) => <LowCurrentStartPage {...props} />
export const TravelingElectricField = (props) => <TravelingElectricFieldPage {...props} />
export const FrequencyElectricField = (props) => <FrequencyElectricFieldPage {...props} />

export const MaterialList = (props) => <MaterialsPage {...props} />
export const MaterialsCheck = (props) => <MaterialsCheckPage {...props} />
export const HalfTest = (props) => <HalfTestPage {...props} />
export const HalfCheck = (props) => <HalfCheckPage {...props} />

export const Circumfluence = (props) => <CircumfluenceTestPage {...props} />

export const TerminalFlow = (props) => <TerminalFlowPage {...props} />

export const InspectionOrder = (props) => <InspectionOrderPage {...props} />
export const TestSchedule = (props) => <TestSchedulePages {...props} />

export const SIMTest = (props) => <SIMTestPage {...props} />
