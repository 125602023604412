/* eslint-disable */
import { toNumber, isFinite } from 'lodash'
Date.prototype.format = function (fmt) {
    // new Date(time).format('yyyy-MM-dd hh:mm:ss');
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length))
        }
    }
    return fmt
}

function decimalToHexString(number) {
    if (number < 0) {
        number = 0xffffffff + number + 1
    }
    return number.toString(16).toUpperCase()
}

export const DateFormat = (str) => {
    if (!str || str.length !== 23) return
    const year = str.slice(0, 4)
    const mouth = str.slice(4, 6)
    const day = str.slice(6, 8)
    const hour = str.slice(8, 10)
    const minute = str.slice(10, 12)
    const second = str.slice(12, 14)
    const millisecond = str.slice(14, 17)
    const microsecond = str.slice(17, 20)
    const Nanosecond = str.slice(20, 23)
    return `${year}-${mouth}-${day} ${hour}:${minute}:${second}  ${millisecond}.${microsecond}.${Nanosecond}`
}

export const compareTimeAndClock = (str, src) => {
    if (!str || str.length !== 23) return
    const year = str.slice(2, 4)
    const mouth = str.slice(4, 6)
    const day = str.slice(6, 8)
    const hour = str.slice(8, 10)
    const minute = str.slice(10, 12)
    const second = str.slice(12, 14)
    const millisecond = str.slice(14, 17)
    const microsecond = str.slice(17, 20)
    const Nanosecond = str.slice(20, 23)
    const str1 = [year, mouth, day, hour, minute, second].map((t1) => decimalToHexString(+t1).padStart(2, '0')).join('')
    const str2 = [millisecond, microsecond, Nanosecond].map((t2) => decimalToHexString(+t2).padStart(4, '0')).join('')
    console.log(str1 + str2, 'compareTimeAndClock')
    return str1 + str2 == src
}

export const parseParams = (uri, params) => {
    const paramsArray = []
    Object.keys(params).forEach((key) => paramsArray.push(`${key}=${params[key]}`))
    if (uri.search(/\?/) === -1) {
        uri += `?${paramsArray.join('&')}`
    } else {
        uri += `&${paramsArray.join('&')}`
    }
    return uri
}

const unitFormat = (i) => {
    let retStr = null
    if (i >= 0 && i < 10) retStr = '0' + i
    else retStr = '' + i
    return retStr
}

export const numberToWorkUpTime = (number) => {
    if (!isFinite(number)) return '00:00:00'
    let timeStr = 0
    let hour = Math.floor(number / 3600)
    let minute = Math.floor((number / 60) % 60)
    let second = number % 60
    return `${unitFormat(hour)}:${unitFormat(minute)}:${unitFormat(second)}`
}

export const workInfoUpTimeToNumber = (str) => {
    let timeNumber = 0
    if (str !== 0 && !str) return
    if (str.length < 0 || !str.indexOf(':') < 0) return
    let array = str.split(':')
    if (!array || array.length !== 3) return timeNumber
    array = array.map((a) => +a.replace(/\b(0+)/gi, ''))
    timeNumber = array[0] * 3600 + array[1] * 60 + array[2]
    return timeNumber
}

// 精简版整数秒转时间格式

const D = (date) => {
    const HH = ~~(date / 3600)
    const MM = ~~((date % 3600) / 60)
    const SS = ~~(date % 60)
    return [HH, MM, SS].map((e) => String(e).padStart(2, '0')).join(':')
}
// 获取url参数
export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == variable) {
            return pair[1]
        }
    }
    return false
}
// 转化正负数
export const getNumber = (theNumber) => {
    if (theNumber > 0) {
        return '+' + theNumber
    } else {
        return theNumber.toString()
    }
}

// 计算点间隔
export const calculationSamp = (samp = 20000000) => {
    let count = 0
    const unit = ['ns', 'us', 'ms']
    const num = 1000000000
    let result = num / Number(samp)
    while (result >= 1000) {
        result = result / 1000
        count++
    }
    return result + unit[count]
}

// 计算点间隔 ns 单位
export const pointTimeBySamp = (samp = 20000000) => {
    let count = 0
    const num = 1000000000
    let result = num / Number(samp)
    return result
}

// 计算采样率
export const calculationUint = (samp = 20000) => {
    let count = 0
    const unit = ['K', 'M']
    let result = Number(samp) / 1000
    while (result > 1000) {
        result = Number(result) / 1000
        count++
    }
    return result + unit[count]
}

export const addKey = (arr, keyWord) => {
    if (!arr || !Array.isArray(arr)) return []
    const list = arr.map((item, index) => {
        return { ...item, key: keyWord ? item[keyWord] : index + 1 }
    })
    return list
}

export const setMinLineList = (arr, minLen) => {
    const initList = []
    for (let i = 0; i < minLen; i += 1) {
        initList.push({
            key: i + 1
        })
    }
    if (!arr) return initList
    if (arr.length < minLen) {
        const result = arr.concat(initList.slice(arr.length, minLen))
        return addKey(result)
    }
    return addKey(arr)
}

const product_env = [
    'mes.ubisor.com',
    'mes.sc.ubisor.com',
    'mes.zy.ubisor.com',
    'mes.cs.ubisor.com',
    'erp.mirror.ubisor.com',
    'mes.mirror.ubisor.com',
    '183.242.22.171:2022'
]
export const isDevelop = () => {
    const host = window.location.host
    return product_env?.find((env) => env?.includes(host)) ? false : true
}

const mirror_env = ['erp.mirror.ubisor.com', 'mes.mirror.ubisor.com']

export const isMirror = () => {
    const host = window.location.host
    return mirror_env?.indexOf(host) !== -1
}

const basicType = ['number', 'string', 'boolean']
//存储localStorage数据
export const setLocalStorage = (name, data) => {
    if (!data) return
    let dataType = typeof data
    //对象
    if (dataType === 'object') {
        window.localStorage.setItem(name, JSON.stringify(data))
    } else if (basicType.indexOf(dataType) >= 0) {
        window.localStorage.setItem(name, data)
    } else {
        console.log('该类型不能用于本地存储')
    }
}

export const getLocalStorage = (name) => {
    let data = window.localStorage.getItem(name)
    const dataType = typeof data
    if (dataType === 'object' || (data?.startsWith('[') && data?.endsWith(']'))) {
        return JSON.parse(data)
    } else if (basicType.indexOf(dataType) >= 0) {
        return data
    } else {
        return ''
    }
}

//清除某个localStorage
export const removeLocalStorage = (name) => {
    window.localStorage.removeItem(name)
}

//清除所有localStorage
export const clearLocalStorage = () => {
    window.localStorage.clear()
}

//存储sessionStorage数据
export const setSessionStorage = (name, data) => {
    let dataType = typeof data
    //对象
    if (dataType === 'object') {
        window.sessionStorage.setItem(name, JSON.stringify(data))
    } else if (basicType.indexOf(dataType) >= 0) {
        window.sessionStorage.setItem(name, data)
    } else {
        console.log('该类型不能用于本地存储')
    }
}

export const getSessionStorage = (name) => {
    let data = window.sessionStorage.getItem(name)
    const dataType = typeof data
    if (dataType === 'object') {
        return JSON.parse(data)
    } else if (basicType.indexOf(dataType) >= 0) {
        return data
    } else {
        return ''
    }
}

export const removeSessionStorage = (name) => {
    window.sessionStorage.removeItem(name)
}

export const getDeepArrKeys = (arr, list, key) => {
    arr.forEach((v) => {
        // 递归取某个字段
        list.push(v[key])
        if (v.child instanceof Array) {
            getDeepArrKeys(v.child, list, key)
        }
    })
    return list
}

export const getDeepArrItem = (arr, list, value, key) => {
    arr.forEach((v) => {
        // 根据条件递归取匹配项目
        if (v[key] === value) {
            list.push(v)
            if (v.child instanceof Array) {
                getDeepArrItem(v.child, list, value, key)
            }
        } else {
            if (v.child instanceof Array) {
                getDeepArrItem(v.child, list, value, key)
            }
        }
    })
    return list
}

export const isAllEqual = (array) => {
    if (array.length > 0) {
        return !array.some(function (value, index) {
            return value !== array[0]
        })
    } else {
        return true
    }
}

export const check_is_img = (url) => {
    return url?.match(/\.(jpeg|jpg|gif|png)$/) != null
}

export const isValidIP = (ip) => {
    var reg =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip)
}
