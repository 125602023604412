/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const AppearancePage = loadable(() => import('./appearance'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const AppearanceDetailPage = loadable(() => import('./appearance/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const AppearanceTempPage = loadable(() => import('./appearance/temp'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const QualityDevicePage = loadable(() => import('./qualityDevice'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const QualityReportPage = loadable(() => import('./qualityReport'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const AfterSalesEquipmentPage = loadable(() => import('./afterSalesEquipment'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const Appearance = (props) => <AppearancePage {...props} />
export const AppearanceDetail = (props) => <AppearanceDetailPage {...props} />
export const AppearanceTemp = (props) => <AppearanceTempPage {...props} />

export const QualityDevice = (props) => <QualityDevicePage {...props} />
export const QualityReport = (props) => <QualityReportPage {...props} />

//售后设备
export const AfterSalesEquipment = (props) => <AfterSalesEquipmentPage {...props} />
