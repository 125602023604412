/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const UserManagePage = loadable(() => import('./userManage'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const RoleManagePage = loadable(() => import('./roles'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const LimitsManagePage = loadable(() => import('./limitsManage'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const UserManage = (props) => <UserManagePage {...props} />
export const RoleManage = (props) => <RoleManagePage {...props} />
export const LimitsManage = (props) => <LimitsManagePage {...props} />
