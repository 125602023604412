import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Wave from './components/wave/index'
import Swiper from './components/swiper/index'

import { filter, map, find, sortBy } from 'lodash'
import { getAuthorityByUserId } from '../../service/service'
import { useUserInfo } from '../../hooks/userInfo'

import './index.scss'

const Home = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [userInfo] = useUserInfo()
    const [menu, setMenu] = useState([])

    // 根据权限处理生成菜单列表
    const dealMenuConfig = (userLevelOne, userLevelTwo, userLevelThree) => {
        let menuTwo = map(userLevelTwo, (ulT) => {
            const levelOnePath = find(userLevelOne, (ulO) => ulO.id === ulT.parentId)
            const levelThreePath = find(userLevelThree, (ulTR) => ulTR.parentId === ulT.id)
            const levelTwoChild = filter(userLevelThree, (ulTR) => ulTR.parentId === ulT.id)
            const userLevelThreeConfig = map(levelTwoChild, (ltC) => {
                ltC.path = `${levelOnePath?.uri}${ulT?.uri}${ltC?.uri || ''}`
                ltC.name = ltC?.accessText
                ltC.disabled = ltC.uri === '/disabled'
                return ltC
            })
            ulT.path = `${levelOnePath?.uri}${ulT?.uri}${levelThreePath?.uri || ''}`
            ulT.disabled = ulT.uri === '/disabled'
            ulT.name = ulT.accessText
            ulT.icon = ''
            ulT.children = sortBy(userLevelThreeConfig, ['score'])
            return ulT
        })

        let menuOneLevel = map(userLevelOne, (ulO) => {
            const firstLevelTwo = find(menuTwo, (uT) => uT.parentId === ulO.id && !uT.disabled)
            ulO.path = firstLevelTwo?.path
            ulO.name = ulO.accessText
            return ulO
        })

        let split_i = Math.ceil((menuOneLevel.length + 1) / 2)
        let newMenuList = menuOneLevel.slice(split_i).concat(menuOneLevel.slice(0, split_i))

        setMenu(newMenuList)
    }
    // 获取权限列表
    const getAuthorList = () => {
        getAuthorityByUserId(userInfo?.id)
            .then((res) => {
                setLoading(false)
                let data = res?.data?.functionList
                if (data) {
                    let userLevelOne = filter(data, (ulO) => {
                        return ulO.parentId === '0' && ulO.accessType === 1
                    })

                    let userLevelTwo = filter(data, (uFl) => {
                        return uFl.parentId !== '0' && uFl.accessType === 1 && uFl.code?.split('-').length - 1 === 1
                    })

                    let userLevelThree = sortBy(
                        filter(data, (uFl) => {
                            return uFl.parentId !== '0' && uFl.accessType === 1 && uFl.code?.split('-').length - 1 === 2
                        }),
                        ['score']
                    )

                    dealMenuConfig(userLevelOne, sortBy(userLevelTwo, ['score'], ['asc']), userLevelThree)
                } else {
                    history.push('/no-auth')
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        // console.log('userInfo', userInfo)
        if (!userInfo?.id) return

        // 获取权限列表
        getAuthorList()
    }, [])

    return (
        <div className="home">
            {menu.length > 0 && <Swiper menu={menu} />}

            <Wave />
        </div>
    )
}

export default Home
