import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { CompatRouter } from 'react-router-dom-v5-compat'

import './utils/mouseCopy' // 注册右键复制事件
import './utils/global' // 注册全局函数
// import * as store from './storeHook/storeHook';
import * as serviceWorker from './serviceWorker'
import { useReducer } from './storeHook/storeHook' // 引入 ant 全局样式

// 设置全局状态
// require
//     .context('./storeHook/reducer', true, /\.js$/)
//     .keys()
//     .forEach((item) => {
//         const temp = require(`./storeHook/reducer/${item.replace('./', '')}`).default
//         if (temp && typeof temp === 'function') {
//             temp.call(null, useReducer)
//         }
//     })

ReactDOM.render(
    <BrowserRouter>
        <CompatRouter>
            <App />
        </CompatRouter>
    </BrowserRouter>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
