import React from "react";
import * as THREE from "three";

import "./index.scss";

class Wave extends React.Component {
	constructor(props) {
		super(props);
		this.renderer = null;
		this.scene = null;
		this.viewBox = React.createRef();
	}

	componentDidMount() {
		// console.log('componentDidMount')
		this.renderWave();
	}
    componentWillUnmount(){
        // 清除threejs缓存
		this.clearThreeCache();
    }
	
	// 清除threejs缓存
	clearThreeCache() {
		let disposeChild = (mesh) => {
			if (mesh instanceof THREE.Mesh) {
				if (mesh.geometry) {
					mesh.geometry.dispose(); //删除几何体
				}
				if (mesh.material) {
					if (mesh.material.texture) {
						mesh.material.texture.dispose(); //删除材质贴图
					}
					mesh.material.dispose(); //删除材质
				}
			}
			if (mesh instanceof THREE.Group) {
				mesh.clear();
			}
			if (mesh instanceof THREE.Object3D) {
				mesh.clear();
			}
		}

		// 清除场景内所有元素
		if(this.scene){
			this.scene.traverse(item => {
				disposeChild(item);
			})
			this.scene.clear();
		}

		// 清除渲染器
		if(this.renderer){
			this.renderer.dispose();
			this.renderer.forceContextLoss();
		}

		// 清除THREE缓存
		THREE.Cache.clear();
	}

	// 渲染波浪
	renderWave() {
		let viewBox = this.viewBox.current;

		let boxWidth = viewBox.clientWidth,
			boxHeight = viewBox.clientHeight;

		let camera;

        // 粒子数组
		let particles = [],
			particle,
			count = 0;
        // 粒子数量、间距
		let SEPARATION = 300,
			AMOUNTX = 50,
			AMOUNTY = 50;
        // 鼠标相对位置
		let mouseX = 0,  
			mouseY = 0;


		// 监听鼠标移动
		const onDocumentMouseMove = (event)=> {
			mouseX = event.clientX - boxWidth / 2;
			mouseY = event.clientY - boxHeight / 2;
		}

        // 渲染场景
        const render = ()=> {
            camera.position.x += (mouseX - camera.position.x) * 0.05;
            // camera.position.y += (-mouseY - camera.position.y) * 0.05;
            camera.lookAt(this.scene.position);
            this.renderer.render(this.scene, camera);
            
            // 绘制波浪
            let i = 0;
            for (let ix = 0; ix < AMOUNTX; ix++) {
                for (let iy = 0; iy < AMOUNTY; iy++) {
                    particle = particles[i++];
                    particle.position.y =
                        Math.sin((ix + count) * 0.3) * 50 +
                        Math.sin((iy + count) * 0.5) * 50;
                    particle.scale.x = particle.scale.y = particle.scale.z =
                        (Math.sin((ix + count) * 0.3) + 1) * 2 +
                        (Math.sin((iy + count) * 0.5) + 1) * 2;
                }
            }
            count += 0.1;
        }
		// 帧渲染
		const animate = ()=> {
			render();
			requestAnimationFrame(animate);
		}

		// 初始化场景
		const init = ()=>{

            // 创建webGL渲染器
			this.renderer = new THREE.WebGLRenderer({
                antialias: true,
                alpha: true
            });
			this.renderer.setSize(boxWidth, boxHeight);
			viewBox.appendChild(this.renderer.domElement);

            // 创建场景
			this.scene = new THREE.Scene();

            // 创建相机
			camera = new THREE.PerspectiveCamera(30, boxWidth / boxHeight, 1, 10000);
			camera.position.y = 2000;
			camera.position.z = 5000;

            // 创建环境光
            let ambientLight = new THREE.AmbientLight(0xffffff);
            this.scene.add(ambientLight);
            

            // 创建粒子
            // 粒子-材质material
			let material = new THREE.MeshBasicMaterial({
				color: 0xffffff,
                transparent: true,
                opacity: 0.2
			});
            // 几何体-geometry
            let geometry = new THREE.SphereGeometry(2, 32, 16);
            let mesh = new THREE.Mesh(geometry, material);
            // 生成所有粒子
            let group = new THREE.Group();
            group.position.z = -5000;
			let i = 0;
			for (let ix = 0; ix < AMOUNTX; ix++) {
				for (let iy = 0; iy < AMOUNTY; iy++) {
					particle = particles[i++] = mesh.clone();
					particle.position.x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
					particle.position.z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
                    group.add(particle);
				}
			}
            this.scene.add(group);
			
			animate();

			document.addEventListener("mousemove", onDocumentMouseMove, false);

		}
		
		init();
	}

	render() {
		return (
			<div className="wavebox" ref={this.viewBox}>
            </div>
		);
	}
}

export default Wave;
