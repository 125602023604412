import React, { useEffect } from 'react'

import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'

import { UserInfoProvider } from './hooks/userInfo'
import RegisterHistory from './Routers'

import 'moment/locale/zh-cn'
import 'antd/dist/antd.css'
import './style/index.styl'

moment.locale('zh-cn')

function App() {
    return (
        <div className="root-app">
            <UserInfoProvider>
                <ConfigProvider locale={zhCN}>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <RegisterHistory />
                    </ErrorBoundary>
                </ConfigProvider>
            </UserInfoProvider>
        </div>
    )
}

export default App

const ErrorFallback = ({ error }) => {
    useEffect(() => {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/
        if (error?.message && chunkFailedMessage.test(error.message)) {
            window.location.reload()
        }
    }, [error])

    return (
        <div className="global-common-base-content">
            <p>网络延迟页面崩溃,请刷新重试!</p>
        </div>
    )
}

ErrorFallback.propTypes = {
    error: PropTypes?.object
}
