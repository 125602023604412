//cSpell:word kehuguanli jiaoseguanli quanxianguanli
export const rPMenu = [
    {
        name: '用户管理',
        icon: 'icon-kehuguanli-01'
    },
    {
        name: '角色管理',
        icon: 'icon-jiaoseguanli-011'
    },
    {
        name: '权限管理',
        icon: 'icon-quanxianguanli-01'
    }
]
