export const supplierMenu = [
    {
        name: '合格供应商',
        icon: 'icon-hegegongyingshang-01'
    },
    {
        name: '潜在供应商',
        icon: 'icon-qianzaigongyingshang-01'
    }
]
