import { PotentialSuppliers, QualifiedSuppliers } from './loader'

const supplierRouters = [
    {
        path: '/supplier-management/qualified-suppliers',
        exact: false,

        component: QualifiedSuppliers
    },
    {
        path: '/supplier-management/potential-suppliers',
        exact: false,

        component: PotentialSuppliers
    }
]
export default supplierRouters
