import React from 'react'
import { Modal, Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useRequest } from 'ahooks'
import PropTypes from 'prop-types'

import authorityIcon from './resource/authority-icon.svg'
import authorityP from './resource/authority-p.svg'
import sysIcon from './resource/sys-icon.svg'
import sysP from './resource/sys-p.svg'
import { sysDetail } from './service'
import { formatTime } from '../../../utils/time'

import './index.styl'

export const SystemUpgrade = ({ open, handleClose, sysId }) => {
    const { data } = useRequest(() => sysDetail(sysId), { ready: !!sysId, refreshDeps: [sysId] })
    const detailData = data?.data || {}
    return (
        <Modal
            title={
                <>
                    <img src={sysIcon} alt="sys-icon" className="img-icon m-r-10" />
                    <span>系统维护</span>
                    <img src={sysP} alt="sys-person-icon" className="p-icon t-40" />
                </>
            }
            open={open}
            closeIcon={<CloseCircleOutlined />}
            onCancel={handleClose}
            className="sys-info-modal"
            footer={null}
            mask={false}
        >
            <div className="title m-b-20">亲爱的用户,你们好!</div>
            <div className="text-65 m-b-20">
                MES系统于<span className="text-blue">{formatTime(detailData?.releaseTime)}</span>开始发布更新
            </div>
            <div className="text-65">更新内容:</div>
            {detailData?.versionContent?.split(';').map((item, index) => (
                <div className="version-content-list" key={index}>
                    {item}
                </div>
            ))}
            <div className="text-65">
                本次更新<span className="text-blue">{detailData?.burninEffct ? '影响' : '不影响'}</span>老化测试
            </div>
            <div className="text-65 m-t-20">
                请您提前保存好手头工作,<span className="text-blue">发布期间避免操作,</span>
                以免造成数据异常
            </div>
            <div>
                <span className="text-65">感谢您的理解与支持</span>❤️
            </div>
        </Modal>
    )
}
SystemUpgrade.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    sysId: PropTypes.string
}

export const AuthorityChange = ({ handleClose, open }) => {
    return (
        <Modal
            title={
                <>
                    <img src={authorityIcon} alt="authority-icon" className="img-icon m-r-10" />
                    <span>权限变更通知</span>
                    <img src={authorityP} alt="authority-person-icon" className="p-icon t-43" />
                </>
            }
            open={open}
            closeIcon={<CloseCircleOutlined />}
            onCancel={handleClose}
            className="sys-info-modal"
            footer={null}
            mask={false}
            maskClosable={false}
        >
            <div className="text-65 t-align-c m-b-40 m-t-20">您的账号权限有所变更，请重新登录</div>
            <Button type="link" block onClick={handleClose}>
                我知道了
            </Button>
        </Modal>
    )
}
AuthorityChange.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool
}
