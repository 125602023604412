import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

// 排程管理-加载页面
// 工序组
const OperationsGroupPage = loadable(() => import('./operationsGroup'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 信息录入
const InformationEntryPage = loadable(() => import('./informationEntry'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 生产排程
const ProductionSchedulingPage = loadable(() => import('./productionScheduling'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 生产排程-详情
const ProductionSchedulingDetailPage = loadable(() => import('./productionScheduling/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 生产报表
const ReportPage = loadable(() => import('./report/ReportPage'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

// 排程管理-路由配置
const scheduleManagementRoutes = [
    {
        // 工序组
        path: '/production-management/scheduleManagement/operationsGroup',
        exact: true,
        component: OperationsGroupPage
    },
    {
        // 信息录入
        path: '/production-management/scheduleManagement/informationEntry',
        exact: true,
        component: InformationEntryPage
    },
    {
        // 生产排程
        path: '/production-management/scheduleManagement/productionScheduling',
        exact: true,
        component: ProductionSchedulingPage
    },
    {
        // 生产排程-详情
        path: '/production-management/scheduleManagement/productionScheduling/detail',
        exact: true,
        component: ProductionSchedulingDetailPage
    },
    {
        // 生产报表
        path: '/production-management/scheduleManagement/report',
        exact: true,
        component: ReportPage
    }
]

export default scheduleManagementRoutes
