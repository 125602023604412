import React, { useState } from 'react'
import { findIndex, uniqBy } from 'lodash'
import { useRequest, useBoolean } from 'ahooks'
import { Skeleton, List, Divider, Empty } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { renderRelativeTime } from '../../../utils/time'
import { useUserInfo } from '../../../hooks/userInfo'
import emptyBg from '../../../assetes/images/empty-bg.svg'
import { getMessageList, putReadStatus } from './service'

//处理名字
export const dealName = (name) => {
    const nameLength = name?.length
    if (nameLength < 2) {
        return name
    } else {
        return name?.slice(1, 2)
    }
}

//设置message isRead状态
export const dealMessageReadStatus = (messageList, mailId, handleMessageList) => {
    const editIndex = findIndex(messageList, (message) => message?.id === mailId)
    const editMessage = messageList?.find((message) => message?.id === mailId)
    editMessage.isRead = true
    messageList?.splice(editIndex, 1, editMessage)
    handleMessageList(messageList)
}
export const EmptyContent = () => {
    return <Empty image={emptyBg} description={<span className="text-25">暂无内容</span>} />
}
export const LoadStatus = ({ isLoadAll, loading, messageList, empty }) => {
    return (
        <>
            {loading ? (
                <Skeleton loading={true} active avatar>
                    <List.Item.Meta title description avatar />
                </Skeleton>
            ) : !messageList?.length ? (
                empty
            ) : (
                ''
            )}
            {isLoadAll && messageList?.length > 0 && <Divider plain>数据已全部加载完毕 🤐</Divider>}
        </>
    )
}
LoadStatus.propTypes = {
    isLoadAll: PropTypes.bool,
    loading: PropTypes.bool,
    messageList: PropTypes.array,
    empty: PropTypes.any
}

export const renderTime = (time) => {
    return (
        <div className="time-info text-25">
            {renderRelativeTime(dayjs(time)) ? (
                <>
                    <span className="m-r-10">{renderRelativeTime(dayjs(time))}</span>
                    <span>{dayjs(time).format('HH:mm:ss')}</span>
                </>
            ) : (
                <>
                    <span className="m-r-10">{dayjs(time).format('YYYY-MM-DD')}</span>
                    <span>{dayjs(time).format('HH:mm:ss')}</span>
                </>
            )}
        </div>
    )
}

export const useMessageListHook = (param = {}) => {
    const [userInfo] = useUserInfo()
    const [messageAllList, setMessageAllList] = useState([])
    const {
        loading,
        data,
        runAsync: loadList
    } = useRequest(getMessageList, {
        manual: true,
        onSuccess: (res) => {
            const list = res?.data?.data
            const newList = list?.length ? uniqBy([...messageAllList, ...list], 'id') : []
            setMessageAllList(newList)
        }
    })
    const handleMessageList = (messageList) => {
        setMessageAllList(messageList)
    }

    const resetMessageList = () => {
        setMessageAllList([])
    }

    const reloadList = (messageType, page = 1) => {
        loadList(userInfo?.id, { type: messageType, page: page, size: 10, ...param })
    }

    return { data, loading, handleMessageList, reloadList, messageAllList, resetMessageList }
}

export const useOpenModalHook = ({
    messageList,
    handleMessageList,
    loadUnReadCount,
    reloadList,
    messageType,
    changReadStatus
}) => {
    const [open, { setTrue, setFalse }] = useBoolean(false)
    const [sysUpdateDetailId, setDetailId] = useState('')

    const handleOpen = (sysUpdateDetailId, inMailId, isRead, isWork) => {
        if (!isWork) setTrue()

        setDetailId(sysUpdateDetailId)
        if (changReadStatus) return changReadStatus(inMailId, messageType, isRead)

        if (!isRead) {
            //处理消息已读状态(状态暂存在本地)
            dealMessageReadStatus(messageList, inMailId, handleMessageList)

            //修改后台已读状态
            putReadStatus(inMailId).then((res) => {
                if (res?.success) {
                    //更新未读数量
                    loadUnReadCount()
                    reloadList(messageType)
                }
            })
        }
    }

    return { open, setFalse, sysUpdateDetailId, handleOpen }
}

export const getLatestUnReadMessage = (messageList, messageType) => {
    if (messageType === 2 || !messageList?.length) return
    if (messageList?.find((message) => message?.sysCode === 3 && message?.isRead)?.id) {
        return {}
    } else {
        return messageList?.find((message) => message?.sysCode === 3 && !message?.isRead) || ''
    }
}
