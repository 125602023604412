import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'
import '../components/common.styl'

//打波标准
const WaveStandsPage = loadable(() => import('./wave'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//老化测试标准
const AgingStandsPage = loadable(() => import('./aging'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const WaveStands = (props) => <WaveStandsPage {...props} />
export const AgingStands = (props) => <AgingStandsPage {...props} />
