/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const ProductConfigPage = loadable(() => import('./productConfig'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const HardwarePage = loadable(() => import('./hardware'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const HardwareNewPage = loadable(() => import('./hardware/new'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const DeviceParamsPage = loadable(() => import('./project/deviceParams'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TerminalMuduleConfigPage = loadable(() => import('./terminalMuduleConfig'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const ProductConfig = (props) => <ProductConfigPage {...props} />
export const Hardware = (props) => <HardwarePage {...props} />
export const HardwareNew = (props) => <HardwareNewPage {...props} />
export const DeviceParams = (props) => <DeviceParamsPage {...props} />
export const TerminalMuduleConfig = (props) => <TerminalMuduleConfigPage {...props} />