import {
    InventorySys,
    OutStorageByKis,
    DeliveryNoteByKis,
    DeliveryNoteLog,
    ShipNeedOrder,
    BadProductList,
    ArrivalOrder,
    WeiWaiArrivalOrder,
    ProjectOrder
} from './loader'

const wMRouters = [
    {
        path: '/warehouse-management/wms/inv',
        exact: true,
        component: InventorySys
    },
    {
        path: '/warehouse-management/wms/kisOutStorage',
        exact: true,
        component: OutStorageByKis
    },
    {
        path: '/warehouse-management/wms/deliveryNotes',
        exact: true,
        component: DeliveryNoteByKis
    },
    {
        path: '/warehouse-management/deliveryNote/log',
        exact: true,
        component: DeliveryNoteLog
    },
    {
        path: '/warehouse-management/sno',
        exact: false,
        component: ShipNeedOrder
    },
    {
        path: '/warehouse-management/bad-product-list',
        exact: true,
        component: BadProductList
    },
    {
        path: '/warehouse-management/arrival-order',
        exact: false,
        component: ArrivalOrder
    },
    {
        path: '/warehouse-management/weiwai-arrival-order',
        exact: false,
        component: WeiWaiArrivalOrder
    },
    {
        path: '/warehouse-management/project-delivery-order',
        exact: false,
        component: ProjectOrder
    }
]

export default wMRouters
