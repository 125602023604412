import {
    ProductQualityData,
    MarketingStatistics,
    MonthQualityData,
    SupplyAndDemand,
    MaterialsData,
    QualityData,
    MaterialsAllData,
    QualityAllData,
    MonthQuality
} from './loader'
const sARouters = [
    {
        path: '/statistical-analysis/production-data/quality-analyze',
        exact: true,

        component: ProductQualityData
    },
    {
        path: '/statistical-analysis/marking/need-actual',
        exact: true,

        component: MarketingStatistics
    },
    {
        path: '/statistical-analysis/marking/need-output',
        exact: true,

        component: MonthQualityData
    },
    {
        path: '/statistical-analysis/production-data/s-a-d',
        exact: true,

        component: SupplyAndDemand
    },
    {
        path: '/statistical-analysis/production-data/month-quality',
        exact: true,

        component: MonthQuality
    },
    {
        path: '/statistical-analysis/quality-data/m-a',
        exact: true,

        component: MaterialsData
    },
    {
        path: '/statistical-analysis/quality-data/q-a',
        exact: true,

        component: QualityData
    },
    {
        path: '/statistical-analysis/quality-data/materialsAll',
        exact: true,

        component: MaterialsAllData
    },
    {
        path: '/statistical-analysis/quality-data/qualityAll',
        exact: true,

        component: QualityAllData
    }
]

export default sARouters
