import {
    Appearance,
    AppearanceDetail,
    AppearanceTemp,
    QualityDevice,
    QualityReport,
    AfterSalesEquipment
} from './quality-management-loader'
import qualityManagementScheduleRoutes from './qualityManagementSchedule/routes'

export const qualityManagementRouters = [
    {
        //外观检测
        path: '/production-management/quality-management/test/appearance-test',
        exact: true,

        component: Appearance
    },
    {
        //外观检测详情
        path: '/production-management/quality-management/test/appearance-test/detail',
        exact: true,

        component: AppearanceDetail
    },
    {
        //外观检测配置
        path: '/production-management/quality-management/test/appearance-test/temp',
        exact: true,

        component: AppearanceTemp
    },
    {
        //质检设备
        path: '/production-management/quality-management/quality-device',
        exact: true,

        component: QualityDevice
    },
    {
        //质检报告
        path: '/production-management/quality-management/quality-report',
        exact: false,

        component: QualityReport
    },
    {
        //售后设备
        path: '/production-management/quality-management/after-sales-equipment',
        exact: false,

        component: AfterSalesEquipment
    },
    ...qualityManagementScheduleRoutes
]
