import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const AboutMePage = loadable(() => import('./appeal/AboutMe'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const MyAppealPage = loadable(() => import('./appeal/MineAppeal/MineAppeal'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const AllAppealPage = loadable(() => import('./appeal/AllAppeal'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const AboutMe = (props) => <AboutMePage {...props} />
export const MyAppeal = (props) => <MyAppealPage {...props} />
export const AllAppeal = (props) => <AllAppealPage {...props} />
