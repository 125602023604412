import React from 'react'
import { Dropdown } from 'antd'

import { apiGet } from '../utils/http'
import { clearLocalStorage } from '../utils/utils'
import useIcon from './resource/use_icon.svg'
import './index.styl'

const onSignOut = () => {
    apiGet('/logout/success').then(() => {
        clearLocalStorage()
        window.historys.push('/login')
    })
}

const UserLogout = () => {
    const userInfo = JSON.parse(window.localStorage.getItem('user')) || {}
    const handleLogOut = (e) => {
        e?.preventDefault()
        onSignOut()
    }
    const items = [
        {
            key: '1',
            label: <span onClick={handleLogOut}>退出登录</span>
        }
    ]
    return (
        <Dropdown
            menu={{
                items
            }}
            placement="bottom"
            overlayClassName="user-log-out-btn"
        >
            <div className="user-name">
                <img src={useIcon} alt="用户头像" />
                <span className="name">{userInfo?.name}</span>
            </div>
        </Dropdown>
    )
}
export default UserLogout
