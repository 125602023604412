//cSpell:word  zhuangpeijixiao tiaoshijixiao zhijianjixiao
export const kpiMenu = [
    {
        name: '装配绩效',
        icon: 'icon-zhuangpeijixiao-01'
    },
    {
        name: '调试绩效',
        icon: 'icon-tiaoshijixiao-011'
    },
    {
        name: '质检绩效',
        icon: 'icon-zhijianjixiao-01'
    }
]
