/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const ProductQualityDataPage = loadable(() => import('./productQualityData'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MarketingStatisticsPage = loadable(() => import('./statistics'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MonthQualityDataPage = loadable(() => import('./statisticsData'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const SupplyAndDemandPage = loadable(() => import('./supplyAndDemand'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MaterialsDataPage = loadable(() => import('./qualityData/materials'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const QualityDataPage = loadable(() => import('./qualityData/quality'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MaterialsAllDataPage = loadable(() => import('./qualityData/materialsAll'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const QualityAllDataPage = loadable(() => import('./qualityData/qualityAll'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const MonthQualityPage = loadable(() => import('./monthQuality'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const ProductQualityData = (props) => <ProductQualityDataPage {...props} />
export const MarketingStatistics = (props) => <MarketingStatisticsPage {...props} />
export const MonthQualityData = (props) => <MonthQualityDataPage {...props} />
export const SupplyAndDemand = (props) => <SupplyAndDemandPage {...props} />
export const MaterialsData = (props) => <MaterialsDataPage {...props} />
export const QualityData = (props) => <QualityDataPage {...props} />
export const MaterialsAllData = (props) => <MaterialsAllDataPage {...props} />
export const QualityAllData = (props) => <QualityAllDataPage {...props} />
export const MonthQuality = (props) => <MonthQualityPage {...props} />
