import {
    Terminals,
    TerminalNanWang,
    TerminalDetail,
    WaveData,
    WaveDetail,
    Compare,
    Blend,
    Upgrade,
    CreateUpgradePlan,
    UpgradePlanDetail,
    Position,
    InitialConfig,
    ConfigCenter,
    AppVersion,
    Setting,
    CloudDevice,
    WarningRouter,
    EquipmentOrigin,
    ParamSetting,
    SysMessage,
    WEBVersion,
    TerminalDebugging,
    IssuancePD,
    IssuanceMain,
    SIMSearch
} from './loader'

const pSRouters = [
    {
        path: '/public-service/terminals',
        exact: true,
        component: Terminals
    },
    {
        path: '/public-service/nan-wang/terminal/:sn',
        exact: true,
        component: TerminalNanWang
    },
    {
        path: '/public-service/terminals/:sn',
        exact: true,

        component: TerminalDetail
    },
    {
        path: '/public-service/waves',
        exact: true,

        component: WaveData
    },
    {
        path: '/public-service/waves/wave-detail/:id',
        exact: true,

        component: WaveDetail
    },
    {
        path: '/public-service/waves/wave-compare',
        exact: true,

        component: Compare
    },
    {
        path: '/public-service/waves/wave-blend',
        exact: true,

        component: Blend
    },
    {
        path: '/public-service/waves/wave-position',
        exact: true,

        component: Position
    },
    {
        path: '/public-service/origin-up',
        exact: true,

        component: Upgrade
    },
    {
        path: '/public-service/origin-up/create',
        exact: true,

        component: CreateUpgradePlan
    },
    {
        path: '/public-service/origin-up/upDetail/:id',
        exact: true,

        component: UpgradePlanDetail
    },
    {
        path: '/public-service/init-config',
        exact: true,

        component: InitialConfig
    },
    {
        path: '/public-service/config-center',
        exact: true,

        component: ConfigCenter
    },
    {
        path: '/public-service/app-version',
        exact: true,

        component: AppVersion
    },
    {
        path: '/public-service/setting',
        exact: true,

        component: Setting
    },
    {
        path: '/public-service/return-terminal',
        exact: true,

        component: CloudDevice
    },
    {
        path: '/public-service/warning',
        exact: false,

        component: WarningRouter
    },
    {
        path: '/public-service/equipment-origin',
        exact: false,

        component: EquipmentOrigin
    },
    {
        path: '/public-service/param-setting',
        exact: false,

        component: ParamSetting
    },
    {
        path: '/public-service/sys-message',
        exact: true,

        component: SysMessage
    },
    {
        path: '/public-service/web-version',
        exact: true,
        component: WEBVersion
    },
    {
        path: '/public-service/terminal-debugging',
        exact: false,
        component: TerminalDebugging
    },
    {
        path: '/public-service/issuance/pd-config',
        exact: true,
        component: IssuancePD
    },
    {
        path: '/public-service/issuance/main-config',
        exact: true,
        component: IssuanceMain
    },
    {
        path: '/public-service/sim-search',
        exact: true,
        component: SIMSearch
    }
]
export default pSRouters
