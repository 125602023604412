/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const TerminalsPage = loadable(() => import('./Terminals'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TerminalNanWangPage = loadable(() => import('./terminalNanWang'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const TerminalDetailPage = loadable(() => import('./Terminals/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const WaveDataPage = loadable(() => import('./waves'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const WaveDetailPage = loadable(() => import('./waves/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const ComparePage = loadable(() => import('./waves/compare'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const BlendPage = loadable(() => import('./waves/blend'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const PositionPage = loadable(() => import('./waves/position'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const UpgradePage = loadable(() => import('./upgrade'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const CreateUpgradePlanPage = loadable(() => import('./upgrade/create'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const UpgradePlanDetailPage = loadable(() => import('./upgrade/detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const InitialConfigPage = loadable(() => import('./initialConfiguration'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const ConfigCenterPage = loadable(() => import('./configCenter/index'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const AppVersionPage = loadable(() => import('./appVersion'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const SettingPage = loadable(() => import('./setting'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const CloudDevicePage = loadable(() => import('./cloudDevice'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const WarningRouterPage = loadable(() => import('./warning/router'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const EquipmentOriginPage = loadable(() => import('./equipmentOrigin'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const ParamSettingPage = loadable(() => import('./paramSetting'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const SysMessagePage = loadable(() => import('./sysMessage'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const WEBVersionPage = loadable(() => import('./webVersion'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const TerminalDebuggingPage = loadable(() => import('./terminalDebugging'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const IssuancePDPage = loadable(() => import('./issuance/pd-config'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const IssuanceMainPage = loadable(() => import('./issuance/main-config'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const SIMSearchPage = loadable(() => import('./SIMSearch'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const Terminals = (props) => <TerminalsPage {...props} />
export const TerminalNanWang = (props) => <TerminalNanWangPage {...props} />
export const TerminalDetail = (props) => <TerminalDetailPage {...props} />
export const WaveData = (props) => <WaveDataPage {...props} />
export const WaveDetail = (props) => <WaveDetailPage {...props} />
export const Compare = (props) => <ComparePage {...props} />
export const Blend = (props) => <BlendPage {...props} />
export const Position = (props) => <PositionPage {...props} />

export const Upgrade = (props) => <UpgradePage {...props} />
export const CreateUpgradePlan = (props) => <CreateUpgradePlanPage {...props} />
export const UpgradePlanDetail = (props) => <UpgradePlanDetailPage {...props} />
export const InitialConfig = (props) => <InitialConfigPage {...props} />
export const ConfigCenter = (props) => <ConfigCenterPage {...props} showConfigVersion={true} />
export const AppVersion = (props) => <AppVersionPage {...props} />
export const Setting = (props) => <SettingPage {...props} />
export const CloudDevice = (props) => <CloudDevicePage {...props} />
export const WarningRouter = (props) => <WarningRouterPage {...props} />
export const EquipmentOrigin = (props) => <EquipmentOriginPage {...props} />
export const ParamSetting = (props) => <ParamSettingPage {...props} />
export const SysMessage = (props) => <SysMessagePage {...props} />
export const WEBVersion = (props) => <WEBVersionPage {...props} />
export const TerminalDebugging = (props) => <TerminalDebuggingPage {...props} />
export const IssuancePD = (props) => <IssuancePDPage {...props} />
export const IssuanceMain = (props) => <IssuanceMainPage {...props} />
export const SIMSearch = (props) => <SIMSearchPage {...props} />
