import { ProductConfig, Hardware, HardwareNew, DeviceParams, TerminalMuduleConfig } from './loader'
const pIRouters = [
    {
        path: '/product-item/product-config',
        exact: true,

        component: ProductConfig
    },
    {
        path: '/product-item/projects/hardware',
        exact: true,

        component: Hardware
    },
    {
        path: '/product-item/projects/new-hardware',
        exact: true,

        component: HardwareNew
    },
    {
        path: '/product-item/projects/deviceParams',
        exact: true,

        component: DeviceParams
    },
    {
        path: '/product-item/terminalMuduleConfig',
        exact: true,

        component: TerminalMuduleConfig
    }
]
export default pIRouters
