import { useEffect } from 'react'

import { apiGet, apiPut } from '../../../utils/http'

//获取用户站内信列表
/**
 *
 * @param {*} userId
 * @param {*} param  type 1系统消息2工作项相关, isRead 0 未读 1已读
 * @returns
 */
export const getMessageList = (userId, param) => {
    return apiGet(`/inmail/user/${userId}/pages`, param)
}

//全部已读功能
export const putReadAll = (userId) => {
    return apiPut(`/inmail/user/${userId}/read`)
}

//获取用户站内信未读数
export const getUnReadCount = (userId) => {
    return apiGet(`/inmail/user/${userId}/unread`)
}

//单条站内信已读
export const putReadStatus = (inmailId) => {
    return apiPut(`/inmail/${inmailId}/read`)
}

//获取版本通知系统详情 id 版本号
export const sysDetail = (id) => {
    return apiGet(`/webUpdate/${id}`)
}

export const testList = (param) => {
    return apiGet('/cloud/sns', param)
}

export const useScrollToBottomHook = (listDomRef, callback, reactionDistance = 0) => {
    useEffect(() => {
        const currentDom = listDomRef.current
        const handleScroll = (e) => {
            if (e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= reactionDistance + 20) {
                callback()
            }
        }
        currentDom?.addEventListener('scroll', handleScroll)
        return () => {
            // 组件销毁时清除绑定事件
            currentDom?.removeEventListener('scroll', handleScroll)
        }
    }, [callback, reactionDistance, listDomRef])
}
