import React from 'react'
import { message as antdMessage } from 'antd'
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'

export const message = {
    success: (params) => {
        if (typeof params === 'string') {
            antdMessage.success({
                content: params,
                className: 'erp-message-success',
                icon: <CheckCircleFilled />
            })
        } else {
            antdMessage.success(params)
        }
    },
    error: (params) => {
        if (typeof params === 'string') {
            antdMessage.error({
                content: params,
                className: 'erp-message-error',
                icon: <CloseCircleFilled />
            })
        } else {
            antdMessage.success(params)
        }
    },
    warn: (params) => {
        if (typeof params === 'string') {
            antdMessage.warn({
                content: params,
                className: 'erp-message-warn',
                icon: <ExclamationCircleFilled />
            })
        } else {
            antdMessage.warn(params)
        }
    },
    info: (params) => {
        if (typeof params === 'string') {
            antdMessage.info({
                content: params,
                className: 'erp-message-info',
                icon: <InfoCircleFilled />
            })
        } else {
            antdMessage.info(params)
        }
    }
}
