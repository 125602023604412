//cSpell:word chukudanguanli  kucunguanli fahuotongzhidan fahuojiludan fahuitongzhidan fahuoxuqiudan
export const wMMenu = [
    {
        name: '出库单管理',
        icon: 'icon-chukudanguanli-01'
    },
    {
        name: '库存管理',
        icon: 'icon-kucunguanli-011'
    },
    {
        name: '不良产品清单',
        icon: 'icon-buliangchanpincangguanli-01'
    },
    {
        name: '发货通知单',
        icon: 'icon-fahuitongzhidan-01'
    },
    {
        name: '发货记录单',
        icon: 'icon-fahuojiludan-011'
    },
    {
        name: '发货需求单',
        icon: 'icon-fahuoxuqiudan-01'
    },
    {
        name: '采购到货单',
        icon: 'icon-caigoudaohuodan-01'
    },
    {
        name: '委外到货单',
        icon: 'icon-weiwaidaohuodan-01'
    },
    {
        name: '项目发货需求单',
        icon: 'icon-xiangmufahuodan-01'
    }
]
