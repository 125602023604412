//cSpell:word shengchanshujufenxi xiaoshoushujufenxi cangchushujufenxi zhijianshujufenxi
export const sAMenu = [
    {
        name: '生产数据分析',
        icon: 'icon-shengchanshujufenxi-01',
        children: [
            {
                name: '生产质量分析'
            }
        ]
    },
    {
        name: '营销数据分析',
        icon: 'icon-xiaoshoushujufenxi-01',
        children: [
            {
                name: '需求与发货统计'
            },
            {
                name: '需求与产量分析'
            }
        ]
    },
    {
        name: '仓储数据分析',
        disabled: true,
        icon: 'icon-cangchushujufenxi-01',
        children: [
            {
                name: '仓库数据分析'
            }
        ]
    },
    {
        name: '质检数据分析',
        icon: 'icon-zhijianshujufenxi-01',
        children: [
            {
                name: '来料分析'
            },
            {
                name: '质检分析'
            }
        ]
    }
]
