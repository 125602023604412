import { ZP, TS, Materials, Product } from './loader'
const kpiRouters = [
    {
        path: '/kpi/z-p',
        exact: true,

        component: ZP
    },
    {
        path: '/kpi/t-s',
        exact: true,

        component: TS
    },
    {
        path: '/kpi/z-j/materials',
        exact: true,

        component: Materials
    },
    {
        path: '/kpi/z-j/product',
        exact: true,

        component: Product
    }
]
export default kpiRouters
