import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './index.styl';


const NoMatch = (props) => {
  const { location } = props;
  return (<div className="no-match">
    <div className="bg" />
    <div className="horizon" />
    <div className="wrapper">
      <div className="left">
        <div className="space-dude" />
      </div>
      <div className="right">
        <div className="content">
          <div className="tip">404</div>
          <h1 className="error-title">传送失败，你已经偏离seibertron星球太远了...</h1>
          <p className="error-detail">
            你所要抵达的
            <span className="color-red">
              {`  ${location.pathname}  `}
            </span>
            不存在，请校验目的地重新发射！！！
          </p>
          <div className="buttons">
            <a href="/" className="go-back">
              <i className="road-sign" />
              <i className="road-sign" />
              <i className="road-sign" />
              <i className="icon-start" />
            </a>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  );
};

NoMatch.propTypes = {
  location: PropTypes.object
};

NoMatch.defaultProps = {
  location: {}
};


export default withRouter(NoMatch);
