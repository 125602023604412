import publicServiceRouters from '../views/public-service/router'
import warehouseManagementRouters from '../views/warehouse-management/router'
import marketingManagementRouters from '../views/marketing-management/router'
import productItemRouters from '../views/product-item/router'
import statisticalAnalysisRouters from '../views/statistical-analysis/router'
import productionManageRouters from '../views/production-management/router'
import rolePermissionRouters from '../views/role-permission/router'
import kpiRouters from '../views/kpi-management/router'
import suggestionsManagementRouters from '../views/suggestions-management/router'
import supplierRouters from '../views/supplier-management/router'
import workFlowRouters from '../views/work-flow/router'


export default [
  ...publicServiceRouters,
  ...warehouseManagementRouters,
  ...marketingManagementRouters,
  ...productItemRouters,
  ...statisticalAnalysisRouters,
  ...productionManageRouters,
  ...rolePermissionRouters,
  ...kpiRouters,
  ...suggestionsManagementRouters,
  ...supplierRouters,
  ...workFlowRouters
];
