/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const InventorySysPage = loadable(() => import('./wms/inventory2'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const OutStorageByKisPage = loadable(() => import('./kisData/outStorageByKis2'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const DeliveryNoteByKisPage = loadable(() => import('./kisData/deliveryNoteByKis'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const DeliveryNoteLogPage = loadable(() => import('./deliveryNote/deliveryNoteLog'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const ShipNeedOrderPage = loadable(() => import('./shipNeedOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const BadProductListPage = loadable(() => import('./badProductList'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//采购到货单
const ArrivalOrderPage = loadable(() => import('./arrivalOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//项目发货需求单
const ProjectOrderPage = loadable(() => import('./projectDeliveryOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//委外到货单
const WeiWaiArrivalOrderPage = loadable(() => import('./weiwaiArrivalOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const InventorySys = (props) => <InventorySysPage {...props} />
export const OutStorageByKis = (props) => <OutStorageByKisPage {...props} />
export const DeliveryNoteByKis = (props) => <DeliveryNoteByKisPage {...props} />
export const DeliveryNoteLog = (props) => <DeliveryNoteLogPage {...props} />
export const ShipNeedOrder = (props) => <ShipNeedOrderPage {...props} />
export const BadProductList = (props) => <BadProductListPage {...props} />
export const ArrivalOrder = (props) => <ArrivalOrderPage {...props} />
export const ProjectOrder = (props) => <ProjectOrderPage {...props} />
export const WeiWaiArrivalOrder = (props) => <WeiWaiArrivalOrderPage {...props} />
