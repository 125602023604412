//cSpell:word Sider ubisor tongzhi wode assetes shousuokuang
import React, { useEffect, useState } from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { filter, find, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import logo from '../assetes/images/logo-1.png'
import logoAll from '../assetes/images/logo-2.svg'
import UserLogout from './UserLogout'
import MessageModal from '../views/public-service/sysMessage/MessageModal'
import { isMirror } from '../utils/utils'
import './index.styl'

const { Header, Sider, Content } = Layout

const CommonLayout = ({ children, userLevelTwoConfig, userLevelOne, userLevelOneConfig }) => {
    let location = useLocation()
    const url = filter(location?.pathname?.split('/'), (v) => v)

    const [collapsed, setCollapsed] = useState(false)
    const [levelOne, setLevelOne] = useState('公共业务')
    const [levelTwo, setLevelTwo] = useState('')
    const [levelThree, setLevelThree] = useState('')
    const [menuActiveKey, setMenuActiveKey] = useState('')

    const toggle = () => {
        setCollapsed((c) => !c)
    }

    const handleLevelOne = (e) => {
        setLevelOne(e?.keyPath[0])
    }

    const handleLevelTwo = (e) => {
        setLevelThree(e?.key)
        document.title = e?.key

        if (e?.key) {
            setMenuActiveKey(e.key)
        } else {
            setMenuActiveKey(null)
        }
    }

    const currentLOne = find(userLevelOne, (m) => m.uri.indexOf(url[0]) !== -1)
    const currentLevelTwo = orderBy(
        filter(userLevelTwoConfig, (ult) => ult.path?.indexOf(url[0]) !== -1),
        ['score'],
        ['asc']
    )

    useEffect(() => {
        setLevelOne(currentLOne?.name)
    }, [currentLOne])

    useEffect(() => {
        let currentLThree = {}
        const currentLT = find(currentLevelTwo, (mT) => {
            if (mT.children?.length) {
                currentLThree = find(mT.children, (mtC) => {
                    return location?.pathname?.indexOf(mtC.path) !== -1
                })
                return currentLThree
            }
            return location?.pathname?.indexOf(mT.path) !== -1
        })
        // console.log('currentLT', currentLT)

        if (currentLT?.children?.length) {
            document.title = currentLThree?.name
            setLevelTwo(currentLT?.name)
            setLevelThree(currentLThree?.name)

            if (menuActiveKey !== currentLThree?.id) {
                setMenuActiveKey(currentLThree?.id)
            }
        } else {
            setLevelTwo('')
            document.title = currentLT?.name || '生产工具'
            setLevelThree(currentLT?.name)

            if (menuActiveKey !== currentLT?.id) {
                setMenuActiveKey(currentLT?.id)
            }
        }
    }, [location, currentLevelTwo])

    const renderMenuData = (data, isFirstLevel = true) => {
        const menuList = data.map((item) => {
            const { id, name, icon, disabled, path, children = [] } = item
            // const key = name
            const key = id

            const menuData = {
                key,
                title: name,
                label: disabled ? name : <Link to={path}>{name}</Link>,
                disabled
            }

            if (isFirstLevel) {
                menuData.icon = <i className={'ant-menu-item-icon iconfont ' + icon} /> || <UserOutlined />
            }

            if (children.length > 0) {
                menuData.children = renderMenuData(children, false)
            }

            return menuData
        })

        // console.log('menuList', menuList)

        return menuList
    }

    return (
        <Layout className="ubisor-common-layout-page">
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={collapsed ? 80 : 180}
                className={classNames('scrollBar', 'sider-scroll', { 'mirror-sider-styl': isMirror() })}
            >
                <div className="ubisor-logo">
                    {isMirror() ? (
                        ' '
                    ) : (
                        <a href="/home">
                            <img src={collapsed ? logo : logoAll} alt="" />
                        </a>
                    )}
                </div>

                {/* 侧边栏导航渲染 */}
                <Menu
                    theme="dark"
                    mode="inline"
                    onClick={handleLevelTwo}
                    // selectedKeys={[levelThree]}
                    selectedKeys={[menuActiveKey]}
                    key={levelOne}
                    items={renderMenuData(currentLevelTwo)}
                ></Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="layout-background layout-header">
                    <i
                        className={`iconfont ${collapsed ? 'icon-shousuokuang2' : 'icon-shousuokuang1'}`}
                        onClick={toggle}
                    />
                    {/* 内容区一级导航渲染 */}
                    <Menu
                        mode="horizontal"
                        onClick={handleLevelOne}
                        selectedKeys={[levelOne]}
                        items={userLevelOneConfig?.map((menuOne) => ({
                            key: menuOne?.name,
                            title: menuOne?.name,
                            disabled: menuOne?.disabled,
                            label: <Link to={menuOne.path}>{menuOne.name}</Link>
                        }))}
                    ></Menu>
                    <div className="right-content">
                        <MessageModal />
                        <UserLogout />
                    </div>
                </Header>
                <Breadcrumb className="layout-bread m-l-24">
                    <Breadcrumb.Item>{levelOne}</Breadcrumb.Item>
                    {levelTwo && <Breadcrumb.Item>{levelTwo}</Breadcrumb.Item>}
                    <Breadcrumb.Item>{levelThree}</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    className={`layout-background m-l-24 layout-content scrollBar ${
                        collapsed ? 'all-w-80' : 'all-w-180'
                    }`}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default React.memo(CommonLayout)
CommonLayout.propTypes = {
    userLevelOneConfig: PropTypes.array,
    userLevelOne: PropTypes.array,
    userLevelTwoConfig: PropTypes.array
}
