import { apiGet, apiPost } from '../utils/http'

/** 2.1. 上传文件接口
 * @param {file} file - 文件
 * @param {string} dir - 存放目录名称
 */
export const apiUploadFile = ({ file, dir, processCallback, cancelCallback } = params) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('dir', dir)
    return apiPost(`/oss/upload`, formData, { processCallback, cancelCallback })
}

//获取产品信息
export const getProductList = () => {
    return apiGet('/terminalDict/open/list')
}

//获取所有批次编号
export const getBatchList = () => {
    return apiGet('/batch/nos')
}

//获取所有测试批次编号
export const getBatchTestList = () => {
    return apiGet('/batch/test/nos')
}

//公共业务接口

/**
 * 新流程生产报告 (复测 调测测试项目产生的数据)
 * type:2:设备抽检 3:问题设备 4:售后维保 5:超期复测 6:产品改制
 */

export const postRetestItem = (param) => {
    return apiPost('/reports/random', param)
}

/**
 * 设备相关接口
 */

// 查询终端列表
export const getTerminalList = (param) => {
    const result = apiGet(`/terminals/list`, param)
    return result
}

//  根据SN原始ID查询
export const getSnAndIdList = async () => {
    const result = await apiGet('/terminals/idAndSn')
    return result?.data?.sns || []
}

//查询所有终端SN
export const getSnList = async () => {
    const result = await apiGet('/terminals/sns')
    return result?.data?.sns || []
}

//查询所有终端ID
export const getIds = async () => {
    const result = await apiGet('/terminals/ids')
    return result?.data || []
}

//通过设备类型获取物料信息
export const getMaterialList = (type) => {
    const result = apiGet(`/wms/materinal/${type}`)
    return result
}

/**
 * 用户相关接口
 */
//查询用户权限
export const getAuthorityByUserId = (userId) => {
    const result = apiGet(`/getFunctionAuthorityByUserId/${userId}`)
    return result
}

// 获取角色成员列表
export const getRoleById = (id) => apiGet(`/role/${id}/member`)

//获取 数据字典
/**
 *
 * @param {*} type
 * 1:分析定位项
 * 2:pdss线径规格
 * 3:配置参数编号
 */
export const getDocumentByType = (type) => apiGet(`/produce/getDocumentByType/${type}`)

/**
 * 获取对应字典数据
 */
export const getDictList = (param) => apiGet(`/dict/list`, param)
