import React, { useContext, useState } from 'react';

const Context = React.createContext(null);

const useStore = () => useContext(Context);

let provideSetStore = null;
function deep(source, keys, val, index = 0) {
  const temp = { [keys[index]]: source[keys[index]] };
  if (keys[index + 1]) {
    temp[keys[index]][keys[index + 1]] = deep(temp, keys, val, index + 1);
  } else {
    temp[keys[index]] = val;
  }
  return temp;
}
const reducer = {
  updateSomeKey(store = {}, { payload, key }) {
    if (typeof key === 'string') {
      if (key.includes('.')) {
        const keys = key.split('.');
        const temp = deep(store, keys, payload);
        return {
          ...store,
          ...temp
        };
      }
      return {
        ...store,
        [key]: payload
      };
    }
    return store;
  }
};
let proStore = {};

function Provider(props) {
  const [store, setStore] = useState(props.store);
  proStore = store;
  provideSetStore = setStore;
  return (
    <Context.Provider
      children={props.children || null}
      value={store}
    />
  );
}

function Consumer(props) {
  return (
    <Context.Consumer
      children={props.children}
    />
  );
}

function useReducer(type, callback) {
  reducer[type] = callback;
}

function useDispatch(action) {
  const { type } = action;
  if (reducer[type]) {
    const data = reducer[type].call(null, proStore, action);
    provideSetStore(data);
  }
}

export {
  Provider,
  Consumer,
  useStore,
  useReducer,
  useDispatch
};
