import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const AbnormalOrderPage = loadable(() => import('./production/abnormalOrder'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const AbnormalOrder = (props) => <AbnormalOrderPage {...props} />
