import { message } from 'antd';

document.onmousedown = event => {
  if (event.which === 3) {
    if (document.execCommand('copy')) {
      if (event.target.attributes && event.target.attributes.mousecopy) {
        event.preventDefault(); // 禁用掉默认事件
        // 禁止右键菜单
        document.oncontextmenu = () => false;
        setTimeout(() => {
          document.oncontextmenu = false;
        }, 200); // 恢复右键菜单
        const txt = event.target.innerText; // 要复制的文本
        const input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');
        input.setAttribute('value', txt);
        document.body.appendChild(input);
        input.setSelectionRange(0, 9999); // 兼容 ios
        input.select();
        document.execCommand('copy');
        message.success('复制成功');
        document.body.removeChild(input);
      }
    }
  }
};
