import {
    ProductionList,
    Supplies,
    ParamsConfig,
    AbnormalDevice,
    ProductionPlan,
    ProductionOrder,
    HalfTest,
    MaterialList,
    MaterialsCheck,
    HalfCheck,
    TerminalFlow,
    InspectionOrder,
    TestSchedule
} from './loader'
import { qualityManagementRouters } from './quality-management-router'

// 排程管理
import scheduleManagementRoutes from './scheduleManagement/routes'
//调试标准
import testStandsRouters from './test-standards/router'
import testRouter from './testRouter'

const pMRouters = [
    ...testRouter,
    {
        //生产装配-已装配设备
        path: '/production-management/assembly/device',
        exact: true,
        component: ProductionList
    },
    {
        //生产装配-物料绑定
        path: '/production-management/assembly/supplies',
        exact: true,
        component: Supplies
    },
    {
        //生产装配-出厂配置
        path: '/production-management/assembly/production/params',
        exact: true,
        component: ParamsConfig
    },
    {
        //问题设备
        path: '/production-management/problem-device',
        exact: false,
        component: AbnormalDevice
    },
    {
        //生产计划
        path: '/production-management/production-plan',
        exact: false,

        component: ProductionPlan
    },
    {
        //调试排程
        path: '/production-management/test-schedule',
        exact: false,
        component: TestSchedule
    },
    {
        //生产订单
        path: '/production-management/production-order',
        exact: false,
        component: ProductionOrder
    },
    {
        //物料清单
        path: '/production-management/materials-management/material-list',
        exact: false,
        component: MaterialList
    },
    {
        path: '/production-management/materials-management/inspection-order',
        component: InspectionOrder,
        exact: false
    },
    {
        //物料检验
        path: '/production-management/materials-management/materials-check',
        exact: false,
        component: MaterialsCheck
    },
    {
        //半成品测试
        path: '/production-management/materials-management/half-test',
        exact: false,
        component: HalfTest
    },
    {
        //半成品检验
        path: '/production-management/materials-management/half-check',
        exact: false,
        component: HalfCheck
    },
    {
        //设备生命周期流程
        path: '/production-management/terminal-flow',
        exact: true,
        component: TerminalFlow
    },

    //质检管理相关路由
    ...qualityManagementRouters,
    // 排程管理路由
    ...scheduleManagementRoutes,
    //调试标准路由
    ...testStandsRouters
]
export default pMRouters
