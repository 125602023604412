import { WaveStands, AgingStands } from './loader'

const testStandsRouters = [
    {
        //打波标准
        path: '/production-management/test-stands/wave',
        exact: true,
        component: WaveStands
    },
    {
        //老化标准
        path: '/production-management/test-stands/aging',
        exact: true,
        component: AgingStands
    }
]
export default testStandsRouters
