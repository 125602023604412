window.btoaPlus = function (str) {
  try {
    return window.btoa(encodeURIComponent(str));
  } catch (error) {
    console.error(str, ':base64编码错误');
  }
  return null;
};
window.atobPlus = function (str) {
  try {
    return decodeURIComponent(window.atob(str));
  } catch (error) {
    console.error(str, ':base64解码错误');
  }
  return null;
};
