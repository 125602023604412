//cSpell:word xiaoshouhetong yingxiaojihua jindiexiaoshoudingdan
export const mMMenu = [
    {
        name: '销售合同',
        icon: 'icon-xiaoshouhetong-01'
    },
    {
        name: '营销计划',
        icon: 'icon-yingxiaojihua-01'
    },
    {
        name: '用友销售订单',
        icon: 'icon-jindiexiaoshoudingdan-01'
    },
    {
        name: '销售账期管理',
        icon: 'icon-shengchanrenwudan-01'
    },
    {
        name: '回款账期管理',
        icon: 'icon-huikuanzhangqi-01'
    },
    {
        name: '招投标平台',
        icon: 'icon-bianzu'
    }
]
