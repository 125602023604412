import React from 'react';
import lottie from "lottie-web";


class Model extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            i: this.props.config.i,
            id: this.props.config.id
        };
        this.ref = React.createRef()
        this.ani = null
    }

    componentDidMount() {
        // console.log(this.state.id)
        // console.log(this.ref.current)
        let id = this.state.id
        let aniJsonPath = `https://ubcloud.oss-cn-beijing.aliyuncs.com/animate/home-${id}/config.json`

        this.ani = lottie.loadAnimation({
            container: this.ref.current, // the dom element that will contain the animation
            path: aniJsonPath, // the path to the animation json
            name: `model-${id}`,
            renderer: "svg",
            loop: true,
            autoplay: true,
        });
        // console.log(this.ani)
    }
    // componnetDidUpdate() {
    //         console.log(this.props.config)
    //     if(this.props.config.i == this.state.i) {
    //         console.log(this.props.config)
    //     //     this.ani.play()
    //     }else{
    //     //     this.ani.pause()
    //     }
    // }
    componentWillUnmount() {
        this.ani.destroy()
    }

    render() {
        let element = (
            <div className='model' ref={this.ref}></div>
        )
        return (
            element
        )
    }
}

export default Model;