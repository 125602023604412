import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'


// 排程管理-加载页面
// 生产排程
const AccountPeriodPage = loadable(() => import('./list'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 生产排程-详情
const AccountPeriodDetailPage = loadable(() => import('./detail'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})



// 排程管理-路由配置
const accountPeriodRoutes = [
    {
        // 账期管理
        path: '/marketing-management/accountPeriod',
        exact: true,
        component: AccountPeriodPage
    },
    {
        // 生产排程-详情
        path: '/marketing-management/accountPeriod/detail',
        exact: true,
        component: AccountPeriodDetailPage
    },
]

export default accountPeriodRoutes