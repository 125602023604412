import { useRequest } from 'ahooks'

import { getAuthorityByUserId } from '../service/service'
import { setLocalStorage } from '../utils/utils'
import { apiGet } from '../utils/http'
import { useUserInfo } from './userInfo'

export const useUserAuthorityList = () => {
    const { data, run: loadUserAuthorityList } = useRequest(getAuthorityByUserId, {
        manual: true,
        onSuccess: (res) => {
            const list = res?.data?.functionList || []
            setLocalStorage('userFunctionList', list)
        }
    })

    return { authorityList: data?.data?.functionList || [], loadUserAuthorityList }
}

export const useUserUnReadCount = () => {
    const [userInfo, dispatch] = useUserInfo()
    //轮询查询未读数量(20分钟)
    const { runAsync: loadUnReadCount } = useRequest(() => apiGet(`/inmail/user/${userInfo?.id}/unread`), {
        pollingInterval: 1000 * 60 * 20,
        ready: !!userInfo?.id,
        onSuccess: (res) => {
            dispatch({ type: 'updateUserInfo', userInfo: { ...userInfo, unReadCount: res?.data } })
        }
    })
    return { loadUnReadCount, userInfo }
}
