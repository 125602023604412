import { AbnormalOrder } from './loader'

const workFlowRouters = [
    {
        path: '/work-flow/production/abnormal-order',
        component: AbnormalOrder
    }
]

export default workFlowRouters
