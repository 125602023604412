import dayjs from 'dayjs'

export const formatTime = (time, formatString = 'YYYY-MM-DD HH:mm:ss') => {
    return time ? dayjs(time)?.format(formatString) : ''
}

//渲染 今天 昨天
export const renderRelativeTime = (time) => {
    if (time >= dayjs().startOf('day').valueOf() && time <= dayjs().endOf('day').valueOf()) {
        return '今天'
    } else if (
        time >= dayjs().subtract(1, 'day').startOf('day').valueOf() &&
        time <= dayjs().subtract(1, 'day').endOf('day').valueOf()
    ) {
        return '昨天'
    } else {
        return ''
    }
}

// 秒数转为时分秒
export const secondToFormatTime = (second = 0) => {
    let h = Math.floor(second / 3600)
    let m = Math.floor((second - h * 3600) / 60)
    let s = second - h * 3600 - m * 60

    let hStr = h < 10 ? '0' + h : h
    let mStr = m < 10 ? '0' + m : m
    let sStr = s < 10 ? '0' + s : s

    return `${hStr}:${mStr}:${sStr}`
}
