//cSpell:word chanpinguanli chanpinbanben yingjianbanben
export const pIMenu = [
    {
        name: '产品管理',
        icon: 'icon-chanpinguanli-011'
    },
    {
        name: '产品版本',
        icon: 'icon-chanpinbanben-01'
    },
    {
        name: '硬件版本',
        icon: 'icon-yingjianbanben-01'
    },
    {
        name: '硬件版本(新)',
        icon: 'icon-yingjianbanben-01'
    },
    {
        name: '终端模块配置',
        icon: 'icon-zhongduanguanli-01'
    }
]
