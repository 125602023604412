import { UserManage, RoleManage, LimitsManage } from './loader'
const rpRouters = [
    {
        path: '/role-p/u-m',
        exact: true,

        component: UserManage
    },
    {
        path: '/role-p/r-m',
        exact: true,

        component: RoleManage
    },
    {
        path: '/role-p/l-m',
        exact: true,

        component: LimitsManage
    }
]
export default rpRouters
